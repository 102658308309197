import * as React from 'react';
import PropTypes from 'prop-types';


import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CreateProposal } from './CreateProposal';
import { ProposalLobMainCoversSelection } from './ProposalLobMainCoversSelection';
import { ProposalProvider } from './Context/ProposalContext';
import { proposalReducer } from './Context/ProposalReducer';
import { ProductProvider } from '../Product/ProductContext';
import { productReducer } from '../Product/ProductReducer';

const TabPanel = ({ value }) => {

    if (value === "quote_details") {
        return (
            <CreateProposal />
        )
    }

    if (value === "general_details") {
        return (
            <ProposalLobMainCoversSelection />
        )
    }

    return (
        <Typography>{value}</Typography>
    )
}

export const Proposal = ({ proposalId = undefined }) => {

    const [value, setValue] = React.useState("quote_summary");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <>
            <ProductProvider reducer={productReducer} productId={"PSC"} >
                <ProposalProvider reducer={proposalReducer} proposalId={123}>
                    <AppBar position="static">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            // indicatorColor="secondary"
                            textColor="inherit"
                            variant='scrollable'
                        >
                            <Tab label="Quote summary" value="quote_summary" />
                            <Tab label="Quote details" value="quote_details" />
                            <Tab label="General details" value="general_details" />
                            <Tab label="Liability" />
                            <Tab label="Property" />
                            <Tab label="Referrals" />
                            <Tab label="Clauses" />
                            <Tab label="Decision" />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} />
                </ProposalProvider>
            </ProductProvider>
        </>
    )
}