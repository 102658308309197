import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { RenderComponent } from '../RenderComponent';

export const RowComponent = ({ item, questions, reactHookFormMethods }) => {

    const enableWhenWatchFields = item.enableWhen ? reactHookFormMethods.watch(item.enableWhen?.watchItems) : null
    const [enabledField, setEnabled] = useState(true);

    useEffect(() => {
        let enable = true;
        const enableWhenValue = item.enableWhen?.watchRules ? item.enableWhen.watchRules : []
        for (let index = 0; index < enableWhenValue.length; index++) {
            const element = enableWhenValue[index];

            if (!element.values.includes(enableWhenWatchFields[index])) {
                enable = false;
            }
        }

        if (enable) {
            setEnabled(true)
        } else {
            setEnabled(false)
        }
    }, [enableWhenWatchFields, item.enableWhen?.watchRules])

    if (enabledField === false) {
        return "";
    }

    return (
        <Grid container>
            {
                item?.items?.map((item, index) => {
                    return (
                        <RenderComponent key={index} item={item} questions={questions} reactHookFormMethods={reactHookFormMethods} />
                    )
                })
            }
        </Grid>
    )
}