import { Grid, Paper, Button } from '@mui/material';
// import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@mui/lab';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { DateComponent } from '../../framework/product/components/DateComponent';
import { RenderComponent } from '../../framework/product/RenderComponent';
import { authProvider } from '../../authProvider';
import useRSocket from '../../lib/useRSocket';
import { RenderPageDefinition } from './RenderPageDefinition';
import useState from 'react-usestateref'
import { useProposalState } from './Context/ProposalContext';
import { ProductProvider, useProductState } from '../Product/ProductContext';
import PropTypes from 'prop-types';
import { productReducer } from '../Product/ProductReducer';
import { getActiveQuestionSets } from '../../framework/common/common';
import useAxios from 'axios-hooks';

const defaultValues = {
    brokerReference: "WILLIS_04223",
    productId: "PSC",
    questionAnswers: {
        "intermediaryName": "name",
        "intermediaryAccountNumber": "",
        "intermediaryTOBACommissionRate": "",
        "intermediaryAccountHandler": ""
    }
}

const questionSets = {
    "generalDetailsQuestions": {
        "questions": [
            "brokerReference",
            "proposedEffectiveDate",
            "policyAccountHandler",
            "intermediaryName",
            "intermediaryAccountNumber",
            "intermediaryTOBACommissionRate",
            "intermediaryAccountHandler",
            "policyholder",
            "organisationType",
            "dateEstablished",
            "bankruptcy",
            "convictions",
            "specialTerms"
        ]
    }
}

const generalDetailsQuestions = {
    "brokerReference": {
        "key": "brokerReference",
        "label": "Broker reference",
        "type": "TEXT"
    },
    "proposedEffectiveDate": {
        "key": "proposedEffectiveDate",
        "label": "Proposed start date",
        "helpText": "Maximum of 45 days in the future",
        "defaultValue": "now",
        "type": "DATE"
    },
    "policyAccountHandler": {
        "key": "policyAccountHandler",
        "label": "Account handler",
        "type": "TEXT"
    },
    "intermediaryName": {
        "key": "intermediaryName",
        "label": "Intermediary name",
        "defaultValue": "default value",
        "type": "TEXT"
    },
    "intermediaryAccountNumber": {
        "key": "intermediaryAccountNumber",
        "label": "Account number",
        "type": "TEXT"
    },
    "intermediaryTOBACommissionRate": {
        "key": "intermediaryTOBACommissionRate",
        "label": "Commission rate (TOBA)",
        "type": "TEXT"
    },
    "intermediaryAccountHandler": {
        "key": "intermediaryAccountHandler",
        "label": "Intermediary account handler",
        "type": "TEXT"
    },
    "policyholder": {
        "key": "policyholder",
        "label": "Name",
        "type": "TEXT"
    },
    "organisationType": {
        "key": "organisationType",
        "label": "Organisation type",
        "type": "SELECT",
        "optionsProps": {
            "type": "STATIC",
            "table": "table_name",
            "options": [
                {
                    "value": 1000000,
                    "label": "Association"
                },
                {
                    "value": 1000001,
                    "label": "Charity"
                },
                {
                    "value": 1000002,
                    "label": "Club"
                },
                {
                    "value": 1000003,
                    "label": "Community interest company"
                },
                {
                    "value": 1000004,
                    "label": "Limited company"
                },
                {
                    "value": 1000005,
                    "label": "Limited liability partnership"
                },
                {
                    "value": 1000006,
                    "label": "Partnership"
                },
                {
                    "value": 1000007,
                    "label": "Public limited company"
                },
                {
                    "value": 1000008,
                    "label": "Sole trader"
                },
                {
                    "value": 1000009,
                    "label": "Other"
                },
                {
                    "value": 1000010,
                    "label": "Residential management company"
                }
            ]
        }
    },
    "dateEstablished": {
        "key": "dateEstablished",
        "label": "Date established",
        "type": "DATE"
    }
}

const generalQuoteDetails = {
    "key": "generalQuoteDetails",
    "title": "General Quote Details",
    "layout": "2 column",
    "items": [
        {
            "type": "section",
            "name": "generalQuoteDetails",
            "title": "General quote details",
            "variant": "h4",
            "items": [
                {
                    "type": "question",
                    "question": "proposedEffectiveDate",
                    "sizeProps": {
                        "xs": 12,
                        "md": 4
                    }
                },
                {
                    "type": "question",
                    "question": "brokerReference",
                    "sizeProps": {
                        "xs": 12,
                        "md": 4
                    }
                },
                {
                    "type": "question",
                    "question": "policyAccountHandler",
                    "sizeProps": {
                        "xs": 12,
                        "md": 4
                    }
                }
            ]
        }
    ]
}

const intermediaryDetailsItems = {
    "key": "intermediaryDetailsItems",
    "title": "Intermediary Details",
    "layout": "2 column",
    "items": [
        {
            "type": "section",
            "name": "intermediaryDetailsSection",
            "title": "Intermediary details",
            "variant": "h4",
            "showAsAccordian": true,
            "items": [
                {
                    "type": "question",
                    "question": "intermediaryName",
                    "formKey": "questionAnswers",
                    "sizeProps": {
                        "xs": 12,
                        "md": 6
                    }
                },
                {
                    "type": "question",
                    "question": "intermediaryAccountNumber",
                    "formKey": "questionAnswers",
                    "sizeProps": {
                        "xs": 12,
                        "md": 6
                    }
                },
                {
                    "type": "question",
                    "question": "intermediaryTOBACommissionRate",
                    "formKey": "questionAnswers",
                    "sizeProps": {
                        "xs": 12,
                        "md": 6
                    }
                },
                {
                    "type": "question",
                    "question": "intermediaryAccountHandler",
                    "formKey": "questionAnswers",
                    "sizeProps": {
                        "xs": 12,
                        "md": 6
                    }
                }
            ]
        }
    ]
}

const mainRiskObjectDetailsItems = {
    "key": "mainRiskObjectDetailsItems",
    "title": "Main risk object details",
    "layout": "2 column",
    "items": [
        {
            "type": "section",
            "name": "mainRiskObjectDetailsSection",
            "title": "Main policyholder details",
            "variant": "h4",
            "showAsAccordian": false,
            "items": [
                {
                    "type": "question",
                    "question": "policyholder",
                    "sizeProps": {
                        "xs": 12,
                        "md": 4
                    }
                },
                {
                    "type": "question",
                    "question": "organisationType",
                    "sizeProps": {
                        "xs": 12,
                        "md": 4
                    }
                },
                {
                    "type": "question",
                    "question": "dateEstablished",
                    "sizeProps": {
                        "xs": 12,
                        "md": 4
                    }
                }
            ]
        }
    ]
}

const proposalUrl = `${process.env.REACT_APP_GPPAS_GATEWAY_URL}/api/v1/proposals`;

export const CreateProposal = () => {

    const [proposalId, setProposalId, proposalIdRef] = useState(null);
    const [socket, client, connected, isError, sendRequestResponse, sendRequestStream] = useRSocket(proposalUrl);


    const [{ data: postData }, executeCreateProposal] = useAxios(
        {
            url: proposalUrl,
            method: 'POST'
        },
        { manual: true }
    )

    const productState = useProductState();


    const reactHookFormMethods = useForm({
        defaultValues: defaultValues
    });

    const { register, handleSubmit, control, reset, setValue, watch, formState } = { ...reactHookFormMethods }
    const watchAllFields = watch();

    const onSubmit = data => {
        const formData = { ...data }
        console.log(formData);
        createProposal(formData)
    }

    const createProposal = async (payload) => {
        console.log(payload)

        try {
            await executeCreateProposal({
                data: { ...payload }
            }).then((response) => {
                console.log(response)
            })
        } catch (e) {

        }
    }

    if (productState.isLoading) return <p>Loading...</p>

    const pageItems = productState.product.productVersions['1'].pageDefinitions['createProposal'].items
    const activeQuestionSets = getActiveQuestionSets(questionSets, watch)

    return (
        <>

            <Grid container >
                <Grid item xs={12} paddingY={1} paddingX={0}>
                    <Paper>
                        <FormProvider {...reactHookFormMethods} >
                            <form onSubmit={reactHookFormMethods.handleSubmit(onSubmit)}>
                                {
                                    generalQuoteDetails.items?.map((item, index) => {
                                        return (
                                            <RenderComponent key={index} item={item} questions={generalDetailsQuestions} questionSets={activeQuestionSets} reactHookFormMethods={reactHookFormMethods} />
                                        )
                                    })
                                }
                                {
                                    mainRiskObjectDetailsItems.items?.map((item, index) => {
                                        return (
                                            <RenderComponent key={index} item={item} questions={generalDetailsQuestions} questionSets={activeQuestionSets} reactHookFormMethods={reactHookFormMethods} />
                                        )
                                    })
                                }
                                {
                                    intermediaryDetailsItems.items?.map((item, index) => {
                                        return (
                                            <RenderComponent key={index} item={item} questions={generalDetailsQuestions} questionSets={activeQuestionSets} reactHookFormMethods={reactHookFormMethods} />
                                        )
                                    })
                                }
                                <RenderPageDefinition questions={productState.product.productVersions['1'].questions} questionSets={activeQuestionSets} items={pageItems} reactHookFormMethods={reactHookFormMethods} />
                                <Grid item xs={12} padding={2} >
                                    <Grid container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center" >
                                        <Button variant="contained" color='primary' type="submit">Start quote</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </FormProvider>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container  >
                <Grid item xs={12}>
                    {JSON.stringify(watchAllFields, 2, 2)}
                </Grid>
            </Grid>

        </>
    )
}

CreateProposal.propTypes = {
    productId: PropTypes.string.isRequired
};