import { Drawer, Toolbar, Typography } from "@mui/material"
import { Box } from "@mui/system"
import AzureAD from "react-aad-msal"
import { authProvider } from "../../../../authProvider"
import PropTypes from 'prop-types';
import { ProposalSidebar } from "../ProposalSidebar";

const drawerWidth = 340;

export const CreateProposalLayout = ({ children }) => {

    return (
        <>
            <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
                <Toolbar />
                {children}
                <div >footer</div>
            </Box>
        </>
    )
}

CreateProposalLayout.propTypes = {
    children: PropTypes.node
};