import { FormControl, FormHelperText, FormLabel, TextField } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import parse from 'html-react-parser';

export const TextFieldComponent = ({ item, questions, reactHookFormMethods, defaultAnswer }) => {

    const { control } = { ...reactHookFormMethods }
    const question = questions[item.question]
    const formName = ( item.level? item.level + "." : "" ) + ( item.formKey? item.formKey + "." : "" ) + question.key;

    return (
        <FormControl fullWidth={true}
        >
            <FormLabel>{parse(question.label)}</FormLabel>
            <Controller
                render={({ field }) => <TextField {...field} size="small" autoComplete="new-password" />}
                name={formName}
                control={control}
                defaultValue={defaultAnswer}
            />
            <FormHelperText>{question.helpText}</FormHelperText>
        </FormControl>
    )
}