import React, { useCallback, useEffect } from 'react';
import { authProvider } from '../../../authProvider';
import useRSocket from '../../../lib/useRSocket';
import useRSocket2 from '../../../lib/useRSocket2';
import { useProposalDispatch, useProposalState } from '../Context/ProposalContext';

const proposalUrl = "ws://localhost:6110";

export const ProposalSubscriptor = ({ children }) => {

    const [socket, client, connected, isError, sendRequestResponse, sendRequestStream] = useRSocket2(proposalUrl);
    const { proposal } = useProposalState();
    const proposalDispatch = useProposalDispatch();

    const updateProposal = useCallback((data) => {
        proposalDispatch({ type: "REFRESH_PROPOSAL", data: data })
    }, [proposalDispatch]);

    useEffect(() => {
        console.log( "useEffect subscribing to", proposal.proposalId)
        if (connected) {
            authProvider.getIdToken().then(token => {
                sendRequestStream(socket, token.idToken.rawIdToken, "get.proposal.subscribe", { proposalId: proposal.proposalId}, (data) => console.log("proposal subscription on subscribe", data), (data) => updateProposal(data), (error) => console.log("update.proposal onError: " + String(error)), (sub) => console.log("update.proposal onSubscribe"))
            })
        }
        return () => {
            return;
        }
    }, [connected, proposal.proposalId, sendRequestStream, socket, updateProposal])

    return (
        <>{children}</>
    )

}