import useAxios from 'axios-hooks';
import React, { useEffect } from 'react'
import useThunkReducer from '../../components/ThunkReducer/ThunkReducer';

const ProductStateContext = React.createContext();
const ProductDispatchContext = React.createContext()

const defaultInitialState = {
    isLoading: true,
    product: {}
}

const ProductProvider = ({ reducer, productId, children }) => {
    const [state, dispatch] = useThunkReducer(reducer, { ...defaultInitialState })

    console.log("ProductProvider for product id %s", productId);
    

    const [productCall, productRefetch] = useAxios(
        process.env.REACT_APP_GPPAS_GATEWAY_URL + '/api/v1/product/' + productId
    )


    useEffect(() => {
        if (productCall.loading) {
            console.log("useEffect productId", productId)
            dispatch({ type: "LOADING_PRODUCT" })
        }

        if (!productCall.loading && !productCall.error) {
            // Loaded the product
            dispatch({ type: "SET_PRODUCT" , data: productCall.data })
        }
    }, [productId, dispatch, productCall])

    return (
        <ProductStateContext.Provider value={state}>
            <ProductDispatchContext.Provider value={dispatch}>
                {children}
            </ProductDispatchContext.Provider>
        </ProductStateContext.Provider>
    )
}


const useProductState = () => {
    const context = React.useContext(ProductStateContext)
    if (context === undefined) {
        throw new Error('useEntityState must be used within a EntityProvider')
    }
    return context
}

function useProductDispatch() {
    const context = React.useContext(ProductDispatchContext)
    if (context === undefined) {
        throw new Error('useEntityDispatch must be used within a EntityProvider')
    }
    return context
}

function useProductContext() {
    return [useProductState, useProductDispatch];
}

export { ProductProvider, useProductState, useProductDispatch, useProductContext }