import { Autocomplete, FormControl, FormHelperText, FormLabel, MenuItem, Select, Skeleton, TextField } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import * as R from "ramda";
import useAxios from 'axios-hooks';

export const AutocompleteComponent = ({ item, questions, reactHookFormMethods }) => {

    const { control, setValue, register } = { ...reactHookFormMethods }
    const question = questions[item.question]
    const formName = ( item.level? item.level + "." : "" ) + ( item.formKey? item.formKey + "." : "" ) + question.key;

    const options = useMemo(() => R.pluck("value", question.optionsProps?.options), [question.optionsProps?.options]);

    const optionsByValue = useMemo(
        () => R.indexBy(R.prop("value"), question.optionsProps?.options),
        [question.optionsProps?.options]
    );

    const getOptionLabel = useCallback(
        (value) => value ? R.path([value, "label"], optionsByValue) : "Please select",
        [optionsByValue]
    );

    const updateValue = (selectedValue, field) => {
        field.onChange(selectedValue);

        if (question.updateField) {
            const index = R.findIndex(R.propEq(question.updateField.valueField, selectedValue))(question.optionsProps?.options)
            if (index !== -1) {
                const row = question.optionsProps?.options[index]
                setValue(question.updateField.targetQuestion, R.path([question.updateField.targetField], row))
            }
        }
    }

    return (
        <>
            <FormControl fullWidth={true} >
                <FormLabel>{question.label}</FormLabel>
                <Controller
                    render={({ field }) => (
                        <Autocomplete size="small"
                            {...field}
                            disablePortal
                            id="combo-box-demo"
                            options={options}
                            getOptionLabel={getOptionLabel}
                            onChange={(_, data) => updateValue(data, field)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    )}
                    name={formName}
                    control={control}
                />
                <FormHelperText>{question.helpText}</FormHelperText>
            </FormControl>
        </>
    )
}

const convertArrayToObjectArray = (array) => {
    const initialValue = [{ label: "Please select", value: "" }];
    return array.reduce((obj, item) => {
        const newEntry = {
            value: item,
            label: item
        }
        return [...obj, newEntry];
    }, initialValue);
};

function getValues(array) {
    if (!array) {
        return [{ label: "Please select", value: "" }]
    }

    if (typeof array[0] === 'string' || array[0] instanceof String) {
        return convertArrayToObjectArray(array)
    }

    return [{ label: "Please select", value: "" }, ...array];
}

export const DynamicAutocompleteComponent = ({ item, questions, reactHookFormMethods }) => {

    const { control, setValue, register } = { ...reactHookFormMethods }
    const question = questions[item.question]
    const formName = ( item.level? item.level + "." : "" ) + ( item.formKey? item.formKey + "." : "" ) + question.key;

    const [dynamicOptions, setDynamicOptions] = React.useState([]);

    const [{ data, loading, error }] = useAxios({
        url: process.env.REACT_APP_GPPAS_POLICY_URL + question.optionsProps?.api,
        method: 'POST',
        data: {}
    })

    const dataValues = getValues(data)

    const options = useMemo(() => R.pluck("value", dataValues ? dataValues : [{ label: "Please select", value: "" }]), [dataValues]);

    const optionsByValue = useMemo(
        () => R.indexBy(R.prop("value"), dataValues ? dataValues : [{ label: "Please select", value: "" }]),
        [dataValues]
    );

    const getOptionLabel = useCallback(
        (value) => value ? R.path([value, "label"], optionsByValue) : "Please select",
        [optionsByValue]
    );

    const updateValue = (selectedValue, field) => {
        field.onChange(selectedValue);

        if (question.updateField) {
            question.updateField.map((value, valueindex) => {
                const index = R.findIndex(R.propEq(value.valueField, selectedValue))(data)
                if (index !== -1) {
                    const row = data[index]
                    setValue(value.targetQuestion, R.path([value.targetField], row))
                }
            })

        }
    }

    if (loading) {
        return "";
    }

    return (
        <>
            <FormControl fullWidth={true} >
                <FormLabel>{question.label}</FormLabel>
                <Controller
                    render={({ field }) => (
                        <Autocomplete size="small"
                            {...field}
                            disablePortal
                            id="combo-box-demo"
                            options={options}
                            getOptionLabel={getOptionLabel}
                            onChange={(_, data) => updateValue(data, field)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    )}
                    name={formName}
                    control={control}
                    defaultValue={""}
                />
                <FormHelperText>{question.helpText}</FormHelperText>
            </FormControl>
        </>
    )
}