import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';

export const TableComponent = ({ item, questions, reactHookFormMethods, fields, tableColumns }) => {

    const noDataLabel = item.noDataLabel ? item.noDataLabel : "no data to display";

    return (
        <Grid container margin={1} >
            <Grid item xs >
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                {tableColumns.map((column, index) => (
                                    <TableCell key={index}>{column.heading}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(fields === null || fields.length === 0) &&
                                <TableRow><TableCell colSpan={tableColumns.length} align="center">{noDataLabel}</TableCell></TableRow>
                            }
                            {fields.map((field, index) => (
                                <TableRow key={field.id}>
                                    {tableColumns.map((column, column_index) => (
                                        <TableCell key={index + '_' + column_index}><span>{field[column.property]}</span></TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}