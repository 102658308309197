import { FormControl, FormHelperText, FormLabel, TextField } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import React from 'react';
import { Controller } from 'react-hook-form';
import enLocale from 'date-fns/locale/en-GB';

export const DateComponent = ({ item, questions, reactHookFormMethods }) => {

    const { control } = { ...reactHookFormMethods }
    const question = questions[item.question]

    const formName = ( item.level? item.level + "." : "" ) + ( item.formKey? item.formKey + "." : "" ) + question.key;
    const dateDefault = question.defaultValue ? ( question.defaultValue === "now" ? new Date() : question.defaultValue)  : null

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={enLocale}>
                <FormControl fullWidth={true} >
                    <FormLabel>{question.label}</FormLabel>
                    <Controller
                        name={formName}
                        control={control}
                        defaultValue={dateDefault}
                        render={({ field }) => (
                            <DatePicker {...field}
                                renderInput={(params) => <TextField {...params} size="small" helperText={null} />
                            } 
                            />
                        )}

                    />
                    <FormHelperText>{question.helpText}</FormHelperText>
                </FormControl>
            </LocalizationProvider>
        </>
    )
}