import { Grid } from '@mui/material';
import React from 'react';
import { RenderComponent } from '../RenderComponent';

export const ActionGroupComponent = ({ item, questions, reactHookFormMethods }) => {

    return (
        <Grid item xs={6} >
            <Grid container margin={0} 
                direction="row"
                {...item.props}
                alignItems="center" >
                {
                    item?.items?.map((item, index) => {
                        return (
                            <RenderComponent key={index} item={item} questions={questions} reactHookFormMethods={reactHookFormMethods} />
                        )
                    })
                }
            </Grid>
        </Grid>
    )
}