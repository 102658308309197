import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { authProvider } from './authProvider';
import { AuthenticationState, AzureAD } from 'react-aad-msal';

ReactDOM.render(
  <React.StrictMode>
    <AzureAD provider={authProvider} >
      {
        ({ login, logout, authenticationState, error, accountInfo }) => {
          switch (authenticationState) {
            case AuthenticationState.Authenticated:
              return (
                <App />
              );
            case AuthenticationState.Unauthenticated:
              return (
                <div>
                  {error && <p><span>An error occurred during authentication, please try again!</span></p>}
                  <p>
                    <span>Hey stranger, you look new!</span>
                    <button onClick={login}>Login</button>
                  </p>
                </div>
              );
            case AuthenticationState.InProgress:
              return (<p>Authenticating...</p>);
            default:
              return (
                <div>
                  {error && <p><span>An error occurred during authentication, please try again!</span></p>}
                  <p>
                    <span>Hey stranger, you look new!</span>
                    <button onClick={login}>Login</button>
                  </p>
                </div>
              );
          }
        }
      }
    </AzureAD>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
