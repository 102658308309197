import {  Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { RenderComponent } from '../RenderComponent';
import { TableComponent } from './TableComponent';

export const DialogFormComponent = ({ item, questions, reactHookFormMethods }) => {


    const reactHookFormDialogMethods = useForm({
        defaultValues: item.defaultValues
    });

    const { handleSubmit, reset } = { ...reactHookFormDialogMethods }
    const { control } = { ...reactHookFormMethods }

    const { fields, append } = useFieldArray({
        control,
        name: item.parent
    });

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        reset();
    };


    const onOk = (data) => {
        append(data)
        handleClose();
    }

    return (
        <>
            <TableComponent item={item} questions={questions} reactHookFormMethods={reactHookFormMethods} fields={fields} tableColumns={item.tableColumns} />
            <Grid item xs margin={1}>
                <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                    Add premises
            </Button>
            </Grid>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="md"
                scroll='paper'
            >
                <DialogTitle id="form-dialog-title">{item.title}</DialogTitle>
                <DialogContent dividers={true}>
                    <DialogFormContent item={item} questions={questions} reactHookFormMethods={reactHookFormDialogMethods} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit(onOk)} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const DialogFormContent = ({ item, questions, reactHookFormMethods }) => {

    return (
        <form>
            <Grid container xs={12}>
                {
                    item?.items?.map((item, index) => {
                        return (
                            <RenderComponent key={index} item={item} questions={questions} reactHookFormMethods={reactHookFormMethods} />
                        )
                    })
                }
            </Grid>
        </form>
    )
}