
import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AppointmentModule from './components/Appointment/AppointmentModule';
import ContactManagementModule from './components/ContactManagement/ContactManagementModule';
import { CreateDemoProposal } from './components/Policy/CreateDemoProposal';
import ActivityModule from './components/Events/ActivityModule';
import { CreateProposal } from './components/Policy/CreateProposal';
import { Proposal } from './components/Policy/Proposal';
import ProposalModule from './components/Policy/Proposal/ProposalModule';
import { ProposalLobMainCoversSelection } from './components/Policy/ProposalLobMainCoversSelection';
import { ProductPage } from './framework/product/ProductPage';
import { CreateProposalLayout } from './components/Policy/Proposal/Layout/CreateProposalLayout';

const AppRoutes = () => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Routes>
                {/* <Redirect
                    exact
                    from="/"
                    to="/product/combinedMedMal"
                /> */}
                <Route path="/activities" exact={false} element={<ActivityModule />} />
                <Route path="/appointments" exact={false} element={<AppointmentModule />} />
                <Route path="/contactmanagement" exact={false} element={<ContactManagementModule />} />
                <Route path="/product/1" exact={false} element={<ProductPage key="generalRiskDetails" pageKey="generalRiskDetails" />} />
                <Route path="/product/demo" exact={false} element={
                    <CreateProposalLayout>
                        <CreateDemoProposal />
                    </CreateProposalLayout>
                } />
                <Route path="/proposals/*" element={<ProposalModule />} />
                <Route path="/" exact={false} element={
                    <Navigate to={"product/demo"} />
                } />
                {/* <Route exact path="/" elemment={<Navigate to="/product/combinedMedMal" />} /> */}
                {/* <Redirect to="/not-found" /> */}
            </Routes>
        </Suspense>
    );
};

export default AppRoutes;