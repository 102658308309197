import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import parse from 'html-react-parser';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';

export const RadioComponent = ({ item, questions, reactHookFormMethods, includeUnknown = false, defaultAnswer }) => {

    const { control } = useFormContext();
    const question = questions[item.question]
    const formName = ( item.level? item.level + "." : "" ) + ( item.formKey? item.formKey + "." : "" ) + question.key;

    return (
        <>
            <FormControl component="fieldset">
                <FormLabel>{parse(question.label)}</FormLabel>
                <Controller
                    render={({ field }) => (
                        <RadioGroup row {...field}  >
                            <FormControlLabel control={<Radio />} value="Yes" label="Yes" />
                            <FormControlLabel control={<Radio />} value="No" label="No" />
                            {
                                includeUnknown &&
                                (
                                    <FormControlLabel control={<Radio />} value="Unknown" label="Unknown" />
                                )
                            }
                        </RadioGroup>
                    )}
                    name={formName}
                    control={control}
                    defaultValue={defaultAnswer}
                    // shouldUnregister={true}
                />
                <FormHelperText>{question.helpText}</FormHelperText>
            </FormControl>

            {/* 
            <Grid container alignItems="bottom" spacing={2}>
                <Grid item sm={12} md={8}>
                    <FormLabel>{parse(question.label)}</FormLabel>
                    <FormHelperText>{question.helpText}</FormHelperText>
                </Grid>
                <Grid item sm={12} md={4} sx={{ display: 'flex', alignContent: 'flex-start' }}>
                    <Controller
                        render={({ field }) => (
                            <RadioGroup row {...field} >
                                <FormControlLabel control={<Radio />} value="Yes" label="Yes" />
                                <FormControlLabel control={<Radio />} value="No" label="No" />
                                {
                                    includeUnknown &&
                                    (
                                        <FormControlLabel control={<Radio />} value="Unknown" label="Unknown" />
                                    )
                                }
                            </RadioGroup>
                        )}
                        name={formName}
                        control={control}
                        defaultValue={defaultAnswer}
                        shouldUnregister={true}
                    />
                </Grid>
            </Grid> */}
        </>
    )
}