import { Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import { RenderComponent } from '../RenderComponent';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const SectionComponent = ({ item, questions, questionSets, reactHookFormMethods }) => {

    if (item.showAsAccordian) {

        var expanded = (item.showExpandedByDefault === undefined) ? false : expanded;
        return (
            <Grid container >
                <Grid item xs={12} margin={1}>
                    <Accordion defaultExpanded={expanded}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant={item.variant}>{item.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                {
                                    item?.items?.map((item, index) => {
                                        return (
                                            <RenderComponent key={index} item={item} questions={questions} questionSets={questionSets} reactHookFormMethods={reactHookFormMethods} />
                                        )
                                    })
                                }
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid >
        )
    }

    return (

        <Grid container >
            <Grid item xs={12} margin={1}>
                <Paper>
                    <Grid container>
                        <Grid item xs={12} padding={1}>
                            {/* <Grid item margin={1} xs={12}> */}
                            <Typography variant={item.variant}>{item.title}</Typography>
                        </Grid>
                        {
                            item?.items?.map((item, index) => {
                                return (
                                    <RenderComponent key={index} item={item} questions={questions} questionSets={questionSets} reactHookFormMethods={reactHookFormMethods} />
                                )
                            })
                        }
                    </Grid>
                </Paper>
            </Grid>
        </Grid >

    )
}
