import { Grid, Paper, Button, Typography } from '@mui/material';
// import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@mui/lab';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { DateComponent } from '../../framework/product/components/DateComponent';
import { RenderComponent } from '../../framework/product/RenderComponent';
import { authProvider } from '../../authProvider';
import useRSocket from '../../lib/useRSocket';
import { RenderPageDefinition } from './RenderPageDefinition';
import useState from 'react-usestateref'
import { useProposalState } from './Context/ProposalContext';
import { ProductProvider, useProductState } from '../Product/ProductContext';
import PropTypes from 'prop-types';
import { productReducer } from '../Product/ProductReducer';
import { getActiveQuestionSets } from '../../framework/common/common';
import useAxios from 'axios-hooks';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NumberFormat from 'react-number-format';
import { Box } from '@mui/system';

const defaultValues = {
    "quoteDetails": {
        "transactionType": "newBusiness",
        "agreementCommissionRate": 25,
        "policyStartDate": new Date(),
        "policyEndDate": new Date( new Date().setFullYear(new Date().getFullYear() + 1) )
    },
    "riskDetails": {
        "turnover": 100000,
        "indemnity": 1000000,
        "previousTotalClaimsAmount": 0,
        "previousNumberOfClaims": 0,
        "yearsSinceMostRecentClaim": 0,
        "policyDiscountRating": 1,
        "coverDiscountRating": 1,
        "lobDiscountRating": 1,
        "medMalCategory": "talkingTherapies"
    }
}

const questionSets = {
    "generalDetailsQuestions": {
        "questions": [
            "transactionType",
            "policyStartDate",
            "policyEndDate",
            "agreementCommissionRate",
            "rateEffectiveDate",
            "turnover",
            "indemnity",
            "medMalCategory",
            "geographicalTurnover.uk",
            "geographicalTurnover.ireland",
            "geographicalTurnover.europe",
            "geographicalTurnover.usaCanada",
            "geographicalTurnover.restOfTheWorld",
            "geographicalTurnover.unknown",
            "advancedBeautyTreatments",
            "highRiskSportsAndFitness",
            "includeAbuse",
            "retroDays",
            "excessDifference",
            "policyDiscountRating",
            "coverDiscountRating",
            "lobDiscountRating",
            "previousTotalClaimsAmount",
            "previousNumberOfClaims",
            "yearsSinceMostRecentClaim"
        ]
    }
}

const generalDetailsQuestions = {


    "transactionType": {
        "key": "transactionType",
        "label": "Transaction type",
        "type": "SELECT",
        "optionsProps": {
            "type": "STATIC",
            "table": "table_name",
            "options": [
                {
                    "value": "newBusiness",
                    "label": "New business"
                },
                {
                    "value": "endorsement",
                    "label": "Endorsement"
                },
                {
                    "value": "renewal",
                    "label": "Renewal"
                }
            ]
        }
    },
    "policyStartDate": {
        "key": "policyStartDate",
        "label": "Policy start date",
        "defaultValue": "now",
        "type": "DATE"
    },
    "policyEndDate": {
        "key": "policyEndDate",
        "label": "Policy end date",
        "type": "DATE"
    },
    "agreementCommissionRate": {
        "key": "agreementCommissionRate",
        "label": "Commission agreement rate",
        "type": "NUMBER"
    },
    "rateEffectiveDate": {
        "key": "rateEffectiveDate",
        "label": "Rate effective date",
        "helpText": "The date that is used to get the rating coefficients, uses policy start date if blank",
        "type": "DATE"
    },
    "turnover": {
        "key": "turnover",
        "label": "Turnover",
        "type": "NUMBER",
        "numberOptions": {
            "decimalScale": 0,
            "prefix": "£"
        }
    },
    "indemnity": {
        "key": "indemnity",
        "label": "Indemnity",
        "type": "SELECT",
        "optionsProps": {
            "type": "STATIC",
            "table": "table_name",
            "options": [
                {
                    "value": "1000000",
                    "label": "£1,000,000"
                },
                {
                    "value": "2000000",
                    "label": "£2,000,000"
                },
                {
                    "value": "5000000",
                    "label": "£5,000,000"
                },
                {
                    "value": "10000000",
                    "label": "£10,000,000"
                }
            ]
        }
    },
    "medMalCategory": {
        "key": "medMalCategory",
        "label": "Category",
        "type": "SELECT",
        "optionsProps": {
            "type": "STATIC",
            "table": "table_name",
            "options": [
                {
                    "value": "talkingTherapies",
                    "label": "Talking therapies"
                },
                {
                    "value": "beauty",
                    "label": "Beauty treatments"
                },
                {
                    "value": "complementary",
                    "label": "Complementary"
                },
                {
                    "value": "sportsAndFitness",
                    "label": "Sports and fitness"
                }
            ]
        }
    },
    "productsTurnover": {
        "key": "productsTurnover",
        "label": "Products turnover",
        "type": "NUMBER"
    },
    "geographicalTurnover.uk": {
        "key": "geographicalTurnover.uk",
        "label": "UK",
        "defaultValue": 100,
        "type": "NUMBER"
    },
    "geographicalTurnover.ireland": {
        "key": "geographicalTurnover.ireland",
        "label": "Ireland",
        "type": "NUMBER"
    },
    "geographicalTurnover.europe": {
        "key": "geographicalTurnover.europe",
        "label": "Europe",
        "type": "NUMBER"
    },
    "geographicalTurnover.usaCanada": {
        "key": "geographicalTurnover.usaCanada",
        "label": "USA and  Canada",
        "type": "NUMBER"
    },
    "geographicalTurnover.restOfTheWorld": {
        "key": "geographicalTurnover.restOfTheWorld",
        "label": "Rest of the world",
        "type": "NUMBER"
    },
    "geographicalTurnover.unknown": {
        "key": "geographicalTurnover.unknown",
        "label": "Unknown",
        "type": "NUMBER"
    },
    "advancedBeautyTreatments": {
        "key": "advancedBeautyTreatments",
        "label": "Advanced beauty treaments",
        "helpText": "Risk has advanced beauty treatments",
        "type": "YES_NO_UNKNOWN"
    },
    "highRiskSportsAndFitness": {
        "key": "highRiskSportsAndFitness",
        "label": "High risk sports and fitness",
        "type": "YES_NO_UNKNOWN"
    },
    "includeAbuse": {
        "key": "includeAbuse",
        "label": "Include abuse",
        "type": "SWITCH"
    },
    "retroDays": {
        "key": "retroDays",
        "label": "Retro cover days",
        "type": "NUMBER"
    },
    "excessDifference": {
        "key": "excessDifference",
        "label": "Excess difference",
        "type": "NUMBER"
    },
    "policyDiscountRating": {
        "key": "policyDiscountRating",
        "label": "Policy discount/loading",
        "type": "NUMBER"
    },
    "lobDiscountRating": {
        "key": "lobDiscountRating",
        "label": "LOB discount/loading",
        "type": "NUMBER"
    },
    "coverDiscountRating": {
        "key": "coverDiscountRating",
        "label": "Cover discount/loading",
        "type": "NUMBER"
    },
    "previousNumberOfClaims": {
        "key": "previousNumberOfClaims",
        "label": "Previous number of claims",
        "type": "NUMBER"
    },
    "previousTotalClaimsAmount": {
        "key": "previousTotalClaimsAmount",
        "label": "Previous total claims amount",
        "type": "NUMBER"
    },
    "yearsSinceMostRecentClaim": {
        "key": "yearsSinceMostRecentClaim",
        "label": "Years since most recent claim",
        "type": "NUMBER"
    }
}

const generalDetailsItems = {
    "key": "generalQuoteDetails",
    "title": "General Quote Details",
    "layout": "2 column",
    "items": [
        {
            "type": "section",
            "name": "generalQuoteDetails",
            "title": "Quote details",
            "variant": "h4",
            "items": [
                {
                    "type": "question",
                    "question": "transactionType",
                    "formKey": "quoteDetails",
                    "sizeProps": {
                        "xs": 12,
                        "md": 4
                    }
                },
                {
                    "type": "question",
                    "question": "policyStartDate",
                    "formKey": "quoteDetails",
                    "sizeProps": {
                        "xs": 12,
                        "md": 4
                    }
                },
                {
                    "type": "question",
                    "question": "policyEndDate",
                    "formKey": "quoteDetails",
                    "sizeProps": {
                        "xs": 12,
                        "md": 4
                    }
                },
                {
                    "type": "question",
                    "question": "agreementCommissionRate",
                    "formKey": "quoteDetails",
                    "sizeProps": {
                        "xs": 12,
                        "md": 4
                    }
                },
                {
                    "type": "question",
                    "question": "rateEffectiveDate",
                    "formKey": "runtimeContext",
                    "sizeProps": {
                        "xs": 12,
                        "md": 4
                    }
                },
                {
                    "type": "section",
                    "name": "discountLoadingSection",
                    "title": "Loadings",
                    "variant": "h5",
                    "showAsAccordian": false,
                    "items": [
                        {
                            "type": "question",
                            "question": "policyDiscountRating",
                            "formKey": "riskDetails",
                            "sizeProps": {
                                "xs": 12,
                                "md": 4
                            }
                        },
                        {
                            "type": "question",
                            "question": "lobDiscountRating",
                            "formKey": "riskDetails",
                            "sizeProps": {
                                "xs": 12,
                                "md": 4
                            }
                        },
                        {
                            "type": "question",
                            "question": "coverDiscountRating",
                            "formKey": "riskDetails",
                            "sizeProps": {
                                "xs": 12,
                                "md": 4
                            }
                        }
                    ]
                }
            ]
        }
    ]
}


const mainRiskObjectDetailsItems = {
    "key": "mainRiskObjectDetailsItems",
    "title": "Main risk object details",
    "layout": "2 column",
    "items": [
        {
            "type": "section",
            "name": "mainRiskObjectDetailsSection",
            "title": "Risk details",
            "variant": "h4",
            "showAsAccordian": false,
            "items": [
                {
                    "type": "question",
                    "question": "turnover",
                    "formKey": "riskDetails",
                    "sizeProps": {
                        "xs": 12,
                        "md": 4
                    }
                },
                {
                    "type": "question",
                    "question": "indemnity",
                    "formKey": "riskDetails",
                    "sizeProps": {
                        "xs": 12,
                        "md": 4
                    }
                },
                {
                    "type": "question",
                    "question": "medMalCategory",
                    "formKey": "riskDetails",
                    "sizeProps": {
                        "xs": 12,
                        "md": 4
                    }
                },
                {
                    "type": "question",
                    "question": "advancedBeautyTreatments",
                    "formKey": "riskDetails",
                    "sizeProps": {
                        "xs": 12,
                        "md": 6
                    }
                },
                {
                    "type": "question",
                    "question": "highRiskSportsAndFitness",
                    "formKey": "riskDetails",
                    "sizeProps": {
                        "xs": 12,
                        "md": 6
                    }
                },
                {
                    "type": "section",
                    "name": "geographicalTurnover",
                    "title": "Geographical turnover",
                    "variant": "h5",
                    "showAsAccordian": false,
                    "items": [
                        {
                            "type": "question",
                            "question": "geographicalTurnover.uk",
                            "formKey": "riskDetails",
                            "sizeProps": {
                                "xs": 12,
                                "md": 2
                            }
                        },
                        {
                            "type": "question",
                            "question": "geographicalTurnover.ireland",
                            "formKey": "riskDetails",
                            "sizeProps": {
                                "xs": 12,
                                "md": 2
                            }
                        },
                        {
                            "type": "question",
                            "question": "geographicalTurnover.europe",
                            "formKey": "riskDetails",
                            "sizeProps": {
                                "xs": 12,
                                "md": 2
                            }
                        },
                        {
                            "type": "question",
                            "question": "geographicalTurnover.usaCanada",
                            "formKey": "riskDetails",
                            "sizeProps": {
                                "xs": 12,
                                "md": 2
                            }
                        },
                        {
                            "type": "question",
                            "question": "geographicalTurnover.restOfTheWorld",
                            "formKey": "riskDetails",
                            "sizeProps": {
                                "xs": 12,
                                "md": 2
                            }
                        },
                        {
                            "type": "question",
                            "question": "geographicalTurnover.unknown",
                            "formKey": "riskDetails",
                            "sizeProps": {
                                "xs": 12,
                                "md": 2
                            }
                        }
                    ]
                },
                {
                    "type": "question",
                    "question": "includeAbuse",
                    "formKey": "riskDetails",
                    "sizeProps": {
                        "xs": 12,
                        "md": 4
                    }
                },
                {
                    "type": "question",
                    "question": "retroDays",
                    "formKey": "riskDetails",
                    "sizeProps": {
                        "xs": 12,
                        "md": 4
                    }
                },
                {
                    "type": "question",
                    "question": "excessDifference",
                    "formKey": "riskDetails",
                    "sizeProps": {
                        "xs": 12,
                        "md": 4
                    }
                },
                {
                    "type": "section",
                    "name": "claimsSection",
                    "title": "Claims",
                    "variant": "h5",
                    "showAsAccordian": false,
                    "items": [
                        {
                            "type": "question",
                            "question": "previousNumberOfClaims",
                            "formKey": "riskDetails",
                            "sizeProps": {
                                "xs": 12,
                                "md": 4
                            }
                        },
                        {
                            "type": "question",
                            "question": "previousTotalClaimsAmount",
                            "formKey": "riskDetails",
                            "sizeProps": {
                                "xs": 12,
                                "md": 4
                            }
                        },
                        {
                            "type": "question",
                            "question": "yearsSinceMostRecentClaim",
                            "formKey": "riskDetails",
                            "sizeProps": {
                                "xs": 12,
                                "md": 4
                            }
                        }
                    ]
                }
            ]
        }
    ]
}

const proposalUrl = `${process.env.REACT_APP_GPPAS_PRICING_BASE_URL}hiscoxdemo-pricing/v1/calculateCombinedMMandPPLPremium`;

export const CreateDemoProposal = () => {

    // const [proposalId, setProposalId, proposalIdRef] = useState(null);
    // const [socket, client, connected, isError, sendRequestResponse, sendRequestStream] = useRSocket(proposalUrl);

    // const productState = useProductState();


    const [{ data: postData, loading: postLoading, error: postError }, executePost] = useAxios(
        {
            url: proposalUrl,
            method: 'POST'
        },
        { manual: true }
    )

    const reactHookFormMethods = useForm({
        defaultValues: defaultValues
    });

    const { register, handleSubmit, control, reset, setValue, watch, formState } = { ...reactHookFormMethods }
    const watchAllFields = watch();

    const onSubmit = (data) => {
        const formData = { ...data }
        executePost({
            data: { ...data }
        })
        // createProposal(postData)
    }

    // function createProposal(payload) {
    //     console.log(payload)
    //     authProvider.getIdToken().then(token => {
    //         sendRequestResponse(socket, token.idToken.rawIdToken, "create.proposal", payload, (data) => setProposalId(data), (error) => console.log("createProposal onError: " + String(error)), (sub) => console.log("createAppointment onSubscribe"))
    //     })
    // }

    // if (productState.isLoading) return <p>Loading...</p>

    // const pageItems = productState.product.productVersions['1'].pageDefinitions['createProposal'].items
    const activeQuestionSets = getActiveQuestionSets(questionSets, watch)

    return (
        <>

            <Grid container >
                <Grid item xs={12} paddingY={1} paddingX={0}>
                    <Paper>
                        <Grid container padding={1}>
                            <Typography variant='h3'>Calculated premiums</Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid item lg={2} md={4} xs={6}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        flexDirection: 'column',
                                        bgcolor: 'background.paper',
                                    }}
                                >
                                    <Box>Annulised gross premium</Box>
                                    <Box>£<NumberFormat
                                        value={postData?.AnnualisedGrossPremium}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        fixedDecimalScale={true}></NumberFormat>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={2} md={4} xs={6}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        flexDirection: 'column',
                                        bgcolor: 'background.paper',
                                    }}
                                >
                                    <Box>Annulised commission</Box>
                                    <Box>£<NumberFormat
                                        value={postData?.AnnualisedCommission} displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        fixedDecimalScale={true}></NumberFormat>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={2} md={4} xs={6}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        flexDirection: 'column',
                                        bgcolor: 'background.paper',
                                    }}
                                >
                                    <Box>Med mal gross premium
                                    </Box>
                                    <Box>£<NumberFormat
                                        value={postData?.MedMalSubCover.AnnualisedGrossPremium} displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        fixedDecimalScale={true}></NumberFormat>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={2} md={4} xs={6}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        flexDirection: 'column',
                                        bgcolor: 'background.paper',
                                    }}
                                >
                                    <Box>Med mal commission</Box>
                                    <Box>£<NumberFormat
                                        value={postData?.MedMalSubCover.AnnualisedCommission} displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        fixedDecimalScale={true}></NumberFormat>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={2} md={4} xs={6}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        flexDirection: 'column',
                                        bgcolor: 'background.paper',
                                    }}
                                >
                                    <Box>PPL gross premium</Box>
                                    <Box>£<NumberFormat
                                        value={postData?.PPLSubCover.AnnualisedGrossPremium} displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        fixedDecimalScale={true}></NumberFormat>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={2} md={4} xs={6}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        flexDirection: 'column',
                                        bgcolor: 'background.paper',
                                    }}
                                >
                                    <Box>PPL commission</Box>
                                    <Box>£<NumberFormat
                                        value={postData?.PPLSubCover.AnnualisedCommission} displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        fixedDecimalScale={true}></NumberFormat>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid item lg={2} md={4} xs={6}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        flexDirection: 'column',
                                        bgcolor: 'background.paper',
                                    }}
                                >
                                    <Box>Transaction gross premium</Box>
                                    <Box>£<NumberFormat
                                        value={postData?.TransactionPremium}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        fixedDecimalScale={true}></NumberFormat>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={2} md={4} xs={6}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        flexDirection: 'column',
                                        bgcolor: 'background.paper',
                                    }}
                                >
                                    <Box>Transaction commission</Box>
                                    <Box>£<NumberFormat
                                        value={postData?.TransactionCommission} displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        fixedDecimalScale={true}></NumberFormat>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={2} md={4} xs={6}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        flexDirection: 'column',
                                        bgcolor: 'background.paper',
                                    }}
                                >
                                    <Box>Med mal transaction gross premium
                                    </Box>
                                    <Box>£<NumberFormat
                                        value={postData?.MedMalSubCover.TransactionPremium} displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        fixedDecimalScale={true}></NumberFormat>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={2} md={4} xs={6}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        flexDirection: 'column',
                                        bgcolor: 'background.paper',
                                    }}
                                >
                                    <Box>Med mal transaction commission</Box>
                                    <Box>£<NumberFormat
                                        value={postData?.MedMalSubCover.TransactionCommission} displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        fixedDecimalScale={true}></NumberFormat>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={2} md={4} xs={6}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        flexDirection: 'column',
                                        bgcolor: 'background.paper',
                                    }}
                                >
                                    <Box>PPL transaction gross premium</Box>
                                    <Box>£<NumberFormat
                                        value={postData?.PPLSubCover.TransactionPremium} displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        fixedDecimalScale={true}></NumberFormat>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={2} md={4} xs={6}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        flexDirection: 'column',
                                        bgcolor: 'background.paper',
                                    }}
                                >
                                    <Box>PPL transaction commission</Box>
                                    <Box>£<NumberFormat
                                        value={postData?.PPLSubCover.TransactionCommission} displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        fixedDecimalScale={true}></NumberFormat>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} paddingY={1} paddingX={0}>
                    <Paper>
                        <FormProvider {...reactHookFormMethods} >
                            <form onSubmit={reactHookFormMethods.handleSubmit(onSubmit)}>
                                {
                                    generalDetailsItems.items?.map((item, index) => {
                                        return (
                                            <RenderComponent key={index} item={item} questions={generalDetailsQuestions} questionSets={activeQuestionSets} reactHookFormMethods={reactHookFormMethods} />
                                        )
                                    })
                                }
                                {
                                    mainRiskObjectDetailsItems.items?.map((item, index) => {
                                        return (
                                            <RenderComponent key={index} item={item} questions={generalDetailsQuestions} questionSets={activeQuestionSets} reactHookFormMethods={reactHookFormMethods} />
                                        )
                                    })
                                }
                                {/* {
                                    intermediaryDetailsItems.items?.map((item, index) => {
                                        return (
                                            <RenderComponent key={index} item={item} questions={generalDetailsQuestions} questionSets={activeQuestionSets} reactHookFormMethods={reactHookFormMethods} />
                                        )
                                    })
                                } */}
                                {/* <RenderPageDefinition questions={productState.product.productVersions['1'].questions} questionSets={activeQuestionSets} items={pageItems} reactHookFormMethods={reactHookFormMethods} /> */}
                                <Grid item xs={12} padding={2} >
                                    <Grid container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center" >
                                        <Button variant="contained" color='primary' type="submit">Generate price</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </FormProvider>
                    </Paper>
                </Grid>
            </Grid>
            <Accordion defaultExpanded={false}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant="h5">Post data</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container  >
                        <Grid item xs={12}>
                            <pre>{JSON.stringify(watchAllFields, 2, 2)}</pre>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={false}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant="h5">Received data</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container  >
                        <Grid item xs={12}>
                            <pre>{JSON.stringify(postData, 2, 2)}</pre>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </>
    )
}

CreateDemoProposal.propTypes = {
};