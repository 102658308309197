import './App.css';
import React from 'react';
// import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer'
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import { authProvider } from './authProvider';
import { AzureAD } from 'react-aad-msal';
import { createTheme, styled, ThemeProvider, darken } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter, HashRouter as Router } from 'react-router-dom';
import AppRoutes from './Routes';
import { Link as NavLink } from 'react-router-dom';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import axios from "axios";
import { Container, Menu, MenuItem, Typography } from '@mui/material';


const drawerWidth = 340;

// const useStyles = makeStyles(theme => ({
//   drawer: {
//     width: 240,
//     [theme.breakpoints.up('lg')]: {
//       marginTop: 64,
//       height: 'calc(100% - 64px)'
//     },
//     overflow: 'auto'
//   },
//   root: {
//     backgroundColor: theme.palette.white,
//     display: 'flex',
//     flexDirection: 'column',
//     height: '100%',
//     padding: theme.spacing(2)
//   },
//   divider: {
//     margin: theme.spacing(2, 0)
//   },
//   nav: {
//     marginBottom: theme.spacing(2)
//   },
//   shiftContent: {
//     paddingLeft: 240,
//     paddingTop: 64,
//     height: '100%',
//     [theme.breakpoints.up('sm')]: {
//       paddingTop: 64
//     }

//   },
//   content: {
//     position: 'absolute',
//     bottom: 64,
//     top: 64,
//     right: 0,
//     left: 240,
//     padding: 8,
//     overflow: 'auto'
//   },
//   footer: {
//     position: 'absolute',
//     padding: 8,
//     bottom: 0,
//     height: 64,
//     width: 'calc(100% - 240px )',
//     overflow: 'hidden',
//     backgroundColor: 'silver'
//   },
// }));

const theme = createTheme({
  typography: {
    fontFamily: 'Manrope',
    fontWeightMedium: 600,
  },
  palette: {
    primary: {
      light: '#475669',
      main: '#1A2C44',
      dark: '#121e2f',
    },
    // primary: {
    //   main: '#e20612'
    // },
    secondary: {
      light: '#339ad3',
      main: '#0081C9',
      dark: '#005a8c'
    },
  },
});


axios.interceptors.request.use(
  async (config) => {
    return authProvider.getIdToken().then(token => {
      if (token) {
        config.headers = {
          authorization: `Bearer ${token.idToken.rawIdToken}`
        };
      }
      return config;
    })
  },
  (error) => Promise.reject(error)
);

function App() {

  // const classes = useStyles();

  const capital = (str) => str.replace(/[^A-Z]+/g, "");

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Box sx={{ display: 'flex' }}>
            {/* <Router
              getUserConfirmation={(message, callback) => {
                // this is the default behavior
                const allowTransition = window.confirm(message);
                callback(allowTransition);
              }}> */}
            <BrowserRouter>
              <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} >
                <Toolbar>
                  <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                    {/* <NavLink to="/product/combinedMedMal"> */}
                    <Button component={NavLink} to="/product/demo" color="inherit">Pricing demo</Button>
                    {/* </NavLink> */}
                    {/* <Button component={NavLink} to="/appointments" color="inherit">Appointments</Button> */}
                    {/* <Button component={NavLink} to="/contactmanagement" color="inherit">Contacts</Button> */}
                    {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}> */}
                    {/*
                      <NavLink to="/appointments" >
                        <Button variant="contained" color="primary">Appointments</Button>
                      </NavLink>                
                      <NavLink to="/activities">
                        <Button variant="contained" color="primary">Activities</Button>
                      </NavLink>
                      <NavLink to="/contactmanagement">
                        <Button variant="contained" color="primary">Contact management</Button>
                      </NavLink>
                      <NavLink to="/proposals/create">
                        <Button variant="contained" color="primary">Create proposal</Button>
                      </NavLink>
                      */}
                    {/* <Button component={NavLink} to="/proposals/create" color="inherit" >Create proposal</Button> */}
                  </Box>
                  <AzureAD provider={authProvider}>
                    {
                      ({ login, logout, authenticationState, error, accountInfo }) => {
                        return (
                          <Box sx={{ flexGrow: 0 }}>
                            <IconButton color="inherit" onClick={handleOpenUserMenu}>
                              <Avatar >{capital(accountInfo.account.name)}</Avatar>
                            </IconButton>
                            <Menu
                              sx={{ mt: '45px' }}
                              id="menu-appbar"
                              anchorEl={anchorElUser}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                              keepMounted
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                              open={Boolean(anchorElUser)}
                              onClose={handleCloseUserMenu}
                            >
                              <MenuItem key="logout" onClick={logout}>
                                <Typography textAlign="center">Logout</Typography>
                              </MenuItem>
                            </Menu>
                          </Box>
                        )
                      }
                    }
                  </AzureAD>
                </Toolbar>
              </AppBar>
              <AppRoutes />
            </BrowserRouter>
          </Box>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
}

export default App;
