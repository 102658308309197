import { useEffect, useRef, useState } from "react"
import { BufferEncoders, IdentitySerializer, MAX_STREAM_ID, MESSAGE_RSOCKET_COMPOSITE_METADATA, RSocketClient } from "rsocket-core"
import RSocketWebSocketClient from "rsocket-websocket-client"
import Metadata from "../metadata"

const sendRequestResponse = (rsocket, bearerToken, route, payload, onCompleteCallback, onErrorCallback, onSubscribeCallback) => {
    const metadata = new Metadata({
        route: route,
        auth: { type: "bearer", token: bearerToken }
    }).toMetadata();


    rsocket.requestResponse({
        data: Buffer.from(JSON.stringify(payload)),
        metadata: metadata
    }).subscribe({
        "onComplete": (data) => {
            console.log("sendRequestResponse onComplete", JSON.parse(data.data.toString()))
            onCompleteCallback(JSON.parse(data.data.toString()))
        },
        "onError": (error) => {
            console.log("sendRequestResponse onError: " + String(error))
            onErrorCallback(error)
        },
        "onSubscribe": (sub) => {
            console.log("sendRequestResponse onSubscribe")
            onSubscribeCallback(sub)
        }
    });
}

const sendRequestStream = (rsocket, bearerToken, route, payload, onCompleteCallback, onNextCallback, onErrorCallback, onSubscribeCallback) => {
    const metadata = new Metadata({
        route: route,
        auth: { type: "bearer", token: bearerToken }
    }).toMetadata();


    rsocket.requestStream({
        data: Buffer.from(JSON.stringify(payload)),
        metadata: metadata
    }).subscribe({
        "onComplete": (data) => {
            console.log("Stream:onComplete", JSON.parse(data.data.toString()))
            // console.log(JSON.parse(data.data.toString()))
            onCompleteCallback(JSON.parse(data.data.toString()))
        },
        "onNext": (data) => {
            console.log("Stream:onNext", JSON.parse(data.data.toString()))
            onNextCallback(JSON.parse(data.data.toString()))
        },
        "onError": (error) => {
            console.log("sendRequestStream onError: " + String(error))
            onErrorCallback(error)
        },
        "onSubscribe": (sub) => {
            sub.request(MAX_STREAM_ID);
            onSubscribeCallback(sub)
        }
    });
}

function getClient(url) {
    const c = new RSocketClient({
        serializers: {
            data: IdentitySerializer,
            metadata: IdentitySerializer
        },
        "setup": {
            "keepAlive": 10000,
            "lifetime": 20000,
            "dataMimeType": "application/json",
            "metadataMimeType": MESSAGE_RSOCKET_COMPOSITE_METADATA.string
        },
        "transport": new RSocketWebSocketClient({
            "url": url,
            "debug": true
        }, BufferEncoders)
    });

    return c;
}

const useRSocket2 = (url) => {

    const [connected, setConnected] = useState(false)
    const [isError, setIsError] = useState(false);
    const [connectedURL, setConnectedURl] = useState(url);

    const [rsocket, setRsocket] = useState(null);
    const rsocketClient = useRef(null)

    useEffect(() => {       
            console.log("creating client")
            rsocketClient.current = getClient(url);

        return () => {
            console.log("cleanup - useEffect with url and rsocketClient")
            if( rsocketClient !== null ) {
                console.log("cleanup - closing client as not null")
                rsocketClient.current.close();
            }
        }
    }, [url]);

    useEffect(() => {
        if (rsocketClient.current !== null && !connected) {
            rsocketClient.current.connect().subscribe({
                onComplete: socket => {
                    console.log("Client onComplete");

                    setRsocket(socket);

                    socket.connectionStatus().subscribe(status => {
                        console.log('Connection status:', status, connectedURL);
                        if (status.kind !== "CONNECTED") {
                            console.log("Clearing client")
                            setRsocket(null);
                            setConnected(false);
                        } else {
                            setConnected(true);
                            setIsError(false)
                        }
                    });

                },
                onError: error => {
                    console.log("Client onError");
                    console.log(error);                  
                    setIsError(true)
                    setConnected(false);
                },
                onSubscribe: cancel => {
                    console.log("Client onSubscribe - cancel");
                }
            })

        }

        return () => {

            console.log("cleanup - conecting rsocket client")
        }
    }, [url, rsocketClient, connected, connectedURL]);

    useEffect(() => {
        console.log("rsocket updated", rsocket)
    }, [rsocket]);

    return [rsocket, rsocketClient, connected, isError, sendRequestResponse, sendRequestStream]
}

export default useRSocket2;