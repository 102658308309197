import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import merge from 'lodash/merge';

export function proposalReducer(state, action) {

    console.log( "action", action)

    switch (action.type) {
        case 'NEW_PROPOSAL':
            return { proposal: {}, isLoading: false, isDirty: false, isSaving: false, isReady: true, isExternalRefresh: false, expectedVersion: null };
        case 'REFRESH_PROPOSAL':
            // If we are currently saving the proposal, then we are expecting it to get refreshed
            if (state.isSaving || state.isLoading) {
                return { ...state, proposal: action.data, isLoading: false, isDirty: false, isSaving: false, isReady: true, isExternalRefresh: false }
            }
            return { ...state, proposal: action.data, isLoading: false, isDirty: false, isSaving: false, isReady: true, isExternalRefresh: true }
        case 'SAVING_PROPOSAL':
            return { ...state, isSaving: true, isExternalRefresh: false };
        case 'EXPECTING_REFRESH':
            return { ...state, isSaving: true, isLoading: true, isExternalRefresh: false, expectedVersion: action.data.version };
        case 'LOADING_PROPOSAL':
            return { ...state, isLoading: true, isExternalRefresh: false };
        default:
            return state;
    }
}