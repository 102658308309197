import { FormControl, FormHelperText, FormLabel, Switch } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

export const SwitchComponent = ({ item, questions, reactHookFormMethods }) => {

    const { control } = { ...reactHookFormMethods }
    const question = questions[item.question]
    const formName = ( item.level? item.level + "." : "" ) + ( item.formKey? item.formKey + "." : "" ) + question.key;

    return (
        <FormControl fullWidth={true}
        >
            <FormLabel>{question.label}</FormLabel>
            <Controller
                render={({ field }) => <Switch {...field} />}
                name={formName}
                control={control}
            />
            <FormHelperText>{question.helpText}</FormHelperText>
        </FormControl>
    )
}