import React, { useEffect } from 'react';
import useAxios from 'axios-hooks'
import { RenderComponent } from '../../framework/product/RenderComponent';
import { useProductState } from '../Product/ProductContext';





/**
 * Get the page definition from the policy service and render it
 */


export const RenderPageDefinition = ({ questions = {}, questionSets = [], items = {}, reactHookFormMethods }) => {

    return (
        <>
            {
                items?.map((item, index) => {
                    return (
                        <RenderComponent key={index} item={item} questions={questions} questionSets={questionSets} reactHookFormMethods={reactHookFormMethods} />
                    )
                })
            }
        </>
    );
}