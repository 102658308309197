import { TabContext } from '@mui/lab';
import { AppBar, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import { useLocation, useParams } from 'react-router';
import { ProductProvider } from '../../Product/ProductContext';
import { productReducer } from '../../Product/ProductReducer';
import { ProposalProvider } from '../Context/ProposalContext';
import { proposalReducer } from '../Context/ProposalReducer';
import { EditProposal } from './EditProposal';
import { EditProposalGeneralDetails, PageConfiguration } from './EditProposalGeneralDetails';
import { StandardProposalLayout } from './Layout/StandardProposalLayout';
import { TabProvider } from './Layout/TabContext';
import { ProposalSubscriptor } from './ProposalSubscriptor';

export const EditPageLayout = () => {

    const { proposalId } = useParams();


    return (
        <ProductProvider reducer={productReducer} productId="PSC">
            <ProposalProvider reducer={proposalReducer} proposalId={proposalId}>
                <ProposalSubscriptor >
                    <StandardProposalLayout>
                        <EditProposal productVersion={"1"} />
                    </StandardProposalLayout>
                </ProposalSubscriptor>
            </ProposalProvider>
        </ProductProvider>
    )
}