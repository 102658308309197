import { Button, Grid, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { authProvider } from '../../../authProvider';
import { getActiveQuestionSets } from '../../../framework/common/common';
import { SwitchComponent } from '../../../framework/product/components/SwitchComponent';
import useRSocket from '../../../lib/useRSocket';
import { useProductState } from '../../Product/ProductContext';
import { useProposalDispatch, useProposalState } from '../Context/ProposalContext';
import { RenderPageDefinition } from '../RenderPageDefinition';

const proposalUrl = "ws://localhost:6110";

export const LobCoverSelection = ({ lobName, pageDefinition, productVersion }) => {

    const productState = useProductState();
    const proposalState = useProposalState();
    const proposalDispatch = useProposalDispatch();

    const lob = productState?.product?.productVersions[productVersion]?.lobs[lobName];
    const covers = lob?.covers ? lob.covers : {};
    const pageItems = lob?.pageDefinitions?.[pageDefinition]?.items ? lob.pageDefinitions[pageDefinition].items : []
    const riskObjectId = proposalState.proposal.lobs[lobName]?.riskObjectIds[0];
    const riskObject = proposalState.proposal.riskObjects[riskObjectId]
    
    const [socket, client, connected, isError, sendRequestResponse, sendRequestStream] = useRSocket(proposalUrl)// process.env.REACT_APP_GPPAS_GATEWAY_WS_URL);

    const reactHookFormMethods = useFormContext();

    const { register, handleSubmit, control, reset, setValue, watch, formState } = { ...reactHookFormMethods }
    const watchAllFields = watch();

    const questionSets = getActiveQuestionSets(lob?.questionSets, watch)

    const onSubmit = data => {
        proposalDispatch({ type: "SAVING_PROPOSAL" })
        const formData = { ...data, proposalId: proposalState.proposal.proposalId, key: lobName }
        console.log(formData);

        authProvider.getIdToken().then(token => {
            sendRequestResponse(socket, token.idToken.rawIdToken, "update.proposal.lob", formData, (data) => { handleResponse(data) }, (error) => console.log("update.proposal onError: " + String(error)), (sub) => console.log("update.proposal onSubscribe"))
            console.log("finished2")
        }).then(() => { console.log("finished") })
    }

    const handleNext = (e) => {
        handleSubmit(onSubmit)()
    }

    const handleSave = (e) => {
        handleSubmit(onSubmit)();
    }

    const handleResponse = (data) => {
        proposalDispatch({ type: "EXPECTING_REFRESH", data: data })
        console.log("dispatch finished ")
        // setTab(nextTab.current);
    }

    return (
        <>
        
            <Grid container >
                <Grid item xs={12} paddingY={1} paddingX={0}>
                    <Paper>

                        <RenderPageDefinition questions={productState.product.productVersions['1'].questions} questionSets={questionSets} items={pageItems} reactHookFormMethods={reactHookFormMethods} />

                        <Grid container >
                            <Grid item xs={12} margin={1}>
                                <Paper>
                                    <Grid container>
                                        <Grid item xs={12} padding={1}>
                                            <Typography variant={'h4'}>Cover selection</Typography>
                                        </Grid>

                                        <Grid item xs={12} padding={1}>
                                            <TableContainer component={Paper}>
                                                <Table size="small" >
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Cover</TableCell>
                                                            <TableCell>{riskObject.name}</TableCell>
                                                            {
                                                                riskObject.subsidiaries.map((subsidiary) => {
                                                                    return (
                                                                        <TableCell>{subsidiary}</TableCell>
                                                                    )
                                                                })
                                                            }
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody >
                                                        {
                                                            lob?.headCovers.map((coverKey) => {
                                                                return (
                                                                    <TableRow key={coverKey} hover>
                                                                        <TableCell>{covers[coverKey].name}</TableCell>
                                                                        <TableCell>
                                                                            <Controller
                                                                                render={({ field }) => <Switch {...field} checked={field.value}/>}
                                                                                name={"mainRiskObjectCoverSelections." + coverKey}
                                                                                control={control}
                                                                                defaultValue={proposalState.proposal.lobs[lobName].mainRiskObjectCoverSelections[coverKey]}
                                                                            />
                                                                        </TableCell>
                                                                        {
                                                                            riskObject.subsidiaries.map((subsidiary) => {
                                                                                return (
                                                                                    <TableCell>
                                                                                        <Controller
                                                                                            render={({ field }) => <Switch {...field} />}
                                                                                            name={"otherRiskObjectCoverSelections." + coverKey + "." + subsidiary}
                                                                                            control={control}
                                                                                            defaultValue={false}
                                                                                        />
                                                                                    </TableCell>
                                                                                )
                                                                            })
                                                                        }
                                                                    </TableRow>
                                                                )
                                                            })
                                                        }

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid >


                        <Grid item xs={12} >
                            <Grid container padding={1}
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center" >
                                <Button sx={{ ml: 2 }} disabled={proposalState.isSaving} variant="outlined" color='secondary' type="button" onClick={(e) => handleSave(e)}>Save</Button>
                                <Button sx={{ ml: 2 }} disabled={proposalState.isSaving} variant="contained" color='primary' type="button" onClick={(e) => handleNext(e)}>Next</Button>
                            </Grid>
                        </Grid>

                    </Paper>
                </Grid>
            </Grid>
            <Grid container  >
                <Grid item xs={12}>
                    <pre>{JSON.stringify(watchAllFields, 2, 2)}</pre>
                </Grid>
            </Grid>
        </>
    )
}

// ({ item, questions, reactHookFormMethods }) 