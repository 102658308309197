import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Activities from './Events';

const EventRoutes = () => {

    return (
        // <Suspense fallback={<div>Loading...</div>}>
            <Routes>
                {/* <Redirect
                    exact
                    from="/activities"
                    to="/activities/dashboard"
                /> */}
                <Route path="/activities/dashboard" exact element={<Activities />} />
                <Route path="/activities/new" exact={true} element={<Activities />} />
                <Route path="/activities/:activityId/edit" exact={true} element={<Activities />} />
                <Route path="/activities/:activityId" exact={true} element={<Activities />} />

                {/* <Redirect to="/not-found" /> */}
            </Routes>
        // </Suspense>
    );
};

export default EventRoutes;