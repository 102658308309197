import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useProposalState, useProposalDispatch } from '../Context/ProposalContext';
import { useProductState } from '../../Product/ProductContext';
import { Button, Grid, Paper } from '@mui/material';
import { RenderPageDefinition } from '../RenderPageDefinition';
import { FormProvider, useForm } from 'react-hook-form';
import useRSocket from '../../../lib/useRSocket';
import { authProvider } from '../../../authProvider';
import { useTabState } from './Layout/TabContext';
import useAxios from 'axios-hooks';
import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';
import mergeWith from 'lodash/mergeWith';
import { getActiveQuestionSets } from '../../../framework/common/common';

const defaultValues = {
}

const proposalUrl = "ws://localhost:6110";

function ignoreNullCustomiser(objectValue, sourceValue) {
    if (sourceValue === null || sourceValue === undefined) {
        return objectValue;
    }
}

const getInitialValues = (dataObject, fields) => {

    var initialValues = pick(dataObject, fields)

    return initialValues;
}

export const EditProposalGeneralDetails = () => {

    const productState = useProductState();
    const proposalState = useProposalState();
    const proposalDispatch = useProposalDispatch();
    const { currentTab, setTab } = useTabState();
    const nextTab = useRef(currentTab);

    const pageFields = productState.product.productVersions['1'].pageDefinitions['generalRiskDetails'].fields
    const pageItems = productState.product.productVersions['1'].pageDefinitions['generalRiskDetails'].items

    const [socket, client, connected, isError, sendRequestResponse, sendRequestStream] = useRSocket( process.env.REACT_APP_GPPAS_POLICY_URL);

    const formInitialValues = getInitialValues(proposalState.proposal, pageFields);

    const reactHookFormMethods = useForm({
        defaultValues: formInitialValues
    });

    const { register, handleSubmit, control, reset, setValue, watch, formState } = { ...reactHookFormMethods }
    const watchAllFields = watch();

    const product = productState?.product?.productVersions['1'];
    const questionSets = getActiveQuestionSets(product?.questionSets, watch)

    const [{ data: postData, loading: postLoading, error: postError }, executePost] = useAxios(
        {
            url: process.env.REACT_APP_GPPAS_GATEWAY_URL + "/api/v1/proposals/wait/" + proposalState.proposal.proposalId,
            method: 'POST'
        },
        { manual: true }
    )

    const onSubmit = data => {
        proposalDispatch({ type: "SAVING_PROPOSAL" })
        const formData = { ...data, proposalId: proposalState.proposal.proposalId }
        console.log(formData);

        // authProvider.getIdToken().then(token => {
        //     sendRequestResponse(socket, token.idToken.rawIdToken, "update.proposal", formData, (data) => { handleResponse(data) }, (error) => console.log("update.proposal onError: " + String(error)), (sub) => console.log("update.proposal onSubscribe"))
        //     console.log("finished2")
        // }).then(() => { console.log("finished") })

        executePost( {
            data: {...formData}
        }).then( () => {
            proposalDispatch({ type: "EXPECTING_REFRESH", data: data })
            console.log("Post done " + postData)
        })
    }

    const handleNext = (e) => {
        handleSubmit(onSubmit)()
        console.log("set next tab")
        nextTab.current = "liability"

    }

    const handleSave = (e) => {
        handleSubmit(onSubmit)();
    }

    const handleResponse = (data) => {
        proposalDispatch({ type: "EXPECTING_REFRESH", data: data })
        console.log("dispatch finished " + nextTab.current)
        setTab(nextTab.current);
    }

    if (productState.isLoading) return <p>Loading...</p>

    return (
        <>

            <Grid container >
                <Grid item xs={12} paddingY={1} paddingX={0}>
                    <Paper>
                        <FormProvider {...reactHookFormMethods} >
                            <form onSubmit={reactHookFormMethods.handleSubmit(onSubmit)}>

                                <RenderPageDefinition questions={productState.product.productVersions['1'].questions} questionSets={questionSets} items={pageItems} reactHookFormMethods={reactHookFormMethods} />

                                <Grid item xs={12} >
                                    <Grid container padding={1}
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center" >
                                        <Button sx={{ ml: 2 }} disabled={proposalState.isSaving} variant="contained" color='primary' type="button" onClick={(e) => handleSave(e)}>Save</Button>
                                        <Button sx={{ ml: 2 }} disabled={proposalState.isSaving} variant="contained" color='primary' type="button" onClick={(e) => handleNext(e)}>Next</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </FormProvider>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container  >
                <Grid item xs={12}>
                    {JSON.stringify(watchAllFields, 2, 2)}
                </Grid>
            </Grid>

        </>
    )
}