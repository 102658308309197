import React from 'react';
import { QuestionComponent } from './components/QuestionComponent';
import { SectionComponent } from './components/SectionComponent';
import { TypographyComponent } from './components/TypographyComponent';
import { RowComponent } from './components/RowComponent';
import { DialogFormComponent } from './components/DialogFormComponent';
import { ActionComponent } from './components/ActionComponent';
import { ActionGroupComponent } from './components/ActionGroupComponent';

export const RenderComponent = ({ item, questions, questionSets, reactHookFormMethods }) => {

    switch (item.type) {
        case 'section':
            return <SectionComponent item={item} questions={questions} questionSets={questionSets} reactHookFormMethods={reactHookFormMethods} />
        case 'row':
            return <RowComponent item={item} questions={questions} reactHookFormMethods={reactHookFormMethods} />
        case 'question':
            return (
                <QuestionComponent item={item} questions={questions} questionSets={questionSets} reactHookFormMethods={reactHookFormMethods} />
            )
        case 'typography':
            return (
                <TypographyComponent item={item} questions={questions} reactHookFormMethods={reactHookFormMethods} />
            )
        case "dialog":
            return <DialogFormComponent item={item} questions={questions} reactHookFormMethods={reactHookFormMethods} />
        case "action":
            return <ActionComponent item={item} questions={questions} reactHookFormMethods={reactHookFormMethods} />
        case "actionGroup":
            return <ActionGroupComponent item={item} questions={questions} reactHookFormMethods={reactHookFormMethods} />
        default:
            return <></>
    }
}