import { TabContext } from '@mui/lab';
import { AppBar, Tab, Tabs, Typography } from '@mui/material';
import useAxios from 'axios-hooks';
import pick from 'lodash/pick';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, Outlet, Route, Routes, useMatch, useParams } from 'react-router-dom';
import { ProductProvider, useProductState } from '../../Product/ProductContext';
import { productReducer } from '../../Product/ProductReducer';
import { ProposalProvider, useProposalDispatch, useProposalState } from '../Context/ProposalContext';
import { proposalReducer } from '../Context/ProposalReducer';
import { EditProposalGeneralDetails, PageConfiguration } from './EditProposalGeneralDetails';
import { EditLineOfBusinessRoutes, EditProposalLineOfBusiness } from './EditProposalLineOfBusiness';
import { EditProposalTab } from './EditProposalTab';
import { StandardProposalLayout } from './Layout/StandardProposalLayout';

const getInitialValues = (dataObject, fields) => {

    var initialValues = pick(dataObject, fields)
    console.log("Initial values", initialValues)
    return initialValues;
}

export const EditProposal = () => {

    const { pageName } = useParams();

    const proposalState = useProposalState();
    const productState = useProductState();
    const productVersion = 1;

    if (productState.isLoading || !proposalState.isReady) {
        return (
            <Typography variant={'h4'}>Loading</Typography>
        )
    }

    const policyPages = productState?.product?.productVersions[productVersion]?.pages;
    const lobs = productState?.product?.productVersions[productVersion]?.lobs ? productState?.product?.productVersions[productVersion]?.lobs : {}

    return (
        <>
            <TabAppBar defaultTab={pageName} />
            <Outlet />

            {/* {
                Object.keys(policyPages).map((key, index) => {
                    console.log("Key", key)

                    if (policyPageName === key) {
                        return (
                            <EditPolicyLevelTabPanel pageDefinition={key} productVersion={productVersion} ></EditPolicyLevelTabPanel>
                        )
                    }
                    return ''
                })
            }
            {
                Object.keys(lobs).map((key, index) => {
                    if (policyPageName === key) {
                        return (
                            <EditLineOfBusinessRoutes key={key} lobName={key} productVersion={productVersion} ></EditLineOfBusinessRoutes>
                        )
                    }

                })
            } */}

        </>
    )
}

export const EditPolicyLevelTabPanel = ({ productVersion }) => {
    const { pageName } = useParams();

    return (
        <EditPolicyLevelTabPanelForm key={pageName} productVersion={productVersion} />
    )
}

export const EditPolicyLevelTabPanelForm = ({ productVersion }) => {

    const { pageName } = useParams();
    const proposalState = useProposalState();
    const productState = useProductState();
    const proposalDispatch = useProposalDispatch();
    const pageFields = productState?.product?.productVersions[productVersion].pageDefinitions[pageName].fields
    const formInitialValues = getInitialValues(proposalState.proposal, pageFields);

    const reactHookFormMethods = useForm({
        defaultValues: formInitialValues,
        shouldUnregister: true
    });



    const [{ data: postData, loading: postLoading, error: postError }, executePost] = useAxios(
        {
            url: process.env.REACT_APP_GPPAS_GATEWAY_URL + "/api/v1/proposals/wait/" + proposalState.proposal.proposalId,
            method: 'POST'
        },
        { manual: true }
    )


    const onSubmit = async (data) => {
        proposalDispatch({ type: "SAVING_PROPOSAL" })
        const formData = { ...data, proposalId: proposalState.proposal.proposalId }
        console.log(formData);

        // authProvider.getIdToken().then(token => {
        //     sendRequestResponse(socket, token.idToken.rawIdToken, "update.proposal", formData, (data) => { handleResponse(data) }, (error) => console.log("update.proposal onError: " + String(error)), (sub) => console.log("update.proposal onSubscribe"))
        //     console.log("finished2")
        // }).then(() => { console.log("finished") })

        await executePost({
            data: { ...formData }
        }).then((response) => {
            proposalDispatch({ type: "EXPECTING_REFRESH", data: response })
            console.log("Post done1 ", response)
        })
    }

    return (
        <>
            <FormProvider {...reactHookFormMethods} >
                <form onSubmit={reactHookFormMethods.handleSubmit(onSubmit)}>
                    <EditProposalTab pageDefinition={pageName} productVersion={productVersion} ></EditProposalTab>
                </form>
            </FormProvider>
        </>
    )
}

const TabAppBar = ({ defaultTab }) => {
    const productState = useProductState();
    const productVersion = 1;
    const [currentTab, setTab] = useState(defaultTab);

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const policyPages = productState?.product?.productVersions[productVersion]?.pages;
    const lobs = productState?.product?.productVersions[productVersion]?.lobs ? productState?.product?.productVersions[productVersion]?.lobs : {}

    return (
        < AppBar position="static">
            <Tabs
                value={currentTab ? currentTab : defaultTab}
                onChange={handleChange}
                // indicatorColor="secondary"
                textColor="inherit"
                variant='scrollable'
            >
                {
                    Object.keys(policyPages).map(key => {
                        const tabTitle = productState?.product?.productVersions[productVersion]?.pageDefinitions[key].title
                        return (
                            <Tab label={tabTitle} value={key} key={key} component={Link} to={"pages/" + key} />
                        )
                    })
                }
                {
                    Object.keys(lobs).map(key => {
                        return (
                            <Tab label={lobs[key].name} value={key} key={key} component={Link} to={"lobs/" + key} />
                        )
                    })
                }
                <Tab label="Referrals" value="referrals" key="referrals" />
                <Tab label="Clauses" value="clauses" key="clauses" />
                <Tab label="Decision" value="decision" key="decision" />
            </Tabs>
        </AppBar>
    )
}