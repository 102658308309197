import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import useState from 'react-usestateref'
import { getPageConfiguration, getQuestionConfigurations } from '../../framework/product/pageUtils';
import { RenderComponent } from '../../framework/product/RenderComponent';

// import { getPageConfiguration, getQuestionConfigurations } from '../../pageUtils';
// import { RenderComponent } from './RenderComponent';


export const TeamForm = ({ pageKey }) => {

    const [loadingPage, setLoadingPage] = useState(true)
    const [pageConfiguration, setPageConfiguration] = useState({})
    const [questions, setQuestions] = useState({});
    const [defaultValues, setDefaultValues] = useState({})

    const reactHookFormMethods = useForm({
        defaultValues: defaultValues
    });

    const { formState, watch, reset } = { ...reactHookFormMethods }

    useEffect(() => {
        reset({ ...defaultValues })
    }, [defaultValues, reset])

    const watchAllFields = watch();

    useEffect(() => {
        // setLoadingPage(true);
        // setTimeout(() => {
            // Load the page data and once loaded set the loadingPage to false
            const pageConfiguration = getPageConfiguration(pageKey)
            setPageConfiguration(pageConfiguration)
            setQuestions(getQuestionConfigurations())
            setDefaultValues(pageConfiguration.defaultValues)
            setLoadingPage(false);
        // }, 200)

        return () => {
            setLoadingPage(true)
        }
    }, [pageKey])


    if (loadingPage) {
        return <h1>Loading...</h1>
    }

    return (
        <>
            <h1>{pageConfiguration.title}</h1>
            <form>
                {
                    pageConfiguration?.items?.map((item, index) => {
                        return (
                            <RenderComponent key={index} item={item} questions={questions} reactHookFormMethods={reactHookFormMethods} />
                        )
                    })
                }
            </form>
            <pre>{JSON.stringify(watchAllFields, 2, 2)}</pre>
            <pre>{JSON.stringify(formState, 2, 2)}</pre>
        </>
    )
}