import React from 'react';
import AppointmentRoutes from './Routes';
import Routes from './Routes';

const AppointmentModule = () => {
    return (
        <AppointmentRoutes />
    )
}

export default AppointmentModule;