import React, { useEffect } from 'react';
import useState from 'react-usestateref'
import { authProvider } from '../../authProvider';
import useRSocket from '../../lib/useRSocket';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Grid, Toolbar } from '@mui/material';
import Button from '@mui/material/Button';
import { intlFormat } from 'date-fns';
import { Box } from '@mui/system';

const appointmentUrl = "ws://localhost:6110";

const dateFormat = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
}
export const AppointmentDashboard = ({ subscribe = true }) => {

    const [appointments, setAppointments, appointmentsRef] = useState([]);
    const [socket, client, connected, isError, sendRequestResponse, sendRequestStream] = useRSocket(appointmentUrl);
    const history = useNavigate();

    useEffect(() => {
        if (connected) {
            authProvider.getIdToken().then(token => {
                sendRequestResponse(socket, token.idToken.rawIdToken, "get.appointments", {}, (data) => setAppointments(data), (error) => console.log("dashbaord onError: " + String(error)), (sub) => console.log("dashbaord onSubscribe"))
                if (subscribe) {
                    sendRequestStream(socket, token.idToken.rawIdToken, "get.appointments.subscribe", {}, (data) => console.log(data), (data) => updateAppointment(data), (error) => console.log("dashbaord onError: " + String(error)), (sub) => console.log("dashbaord onSubscribe"))
                }
            })
        }
    }, [connected])

    const updateAppointment = (updatedAppointment) => {
        const index = appointmentsRef.current.findIndex(app => app.appointmentId === updatedAppointment.appointmentId)

        console.log(index)
        console.log(updatedAppointment)

        if (index === -1) {
            const updatedAppointments = [...appointmentsRef.current];
            updatedAppointments.push(updatedAppointment)
            console.log(updatedAppointments)
            setAppointments(updatedAppointments)
        }
        else {
            const updatedAppointments = [...appointmentsRef.current];
            updatedAppointments[index] = updatedAppointment;
            console.log(updatedAppointments)
            setAppointments(updatedAppointments);
        }
        // if (appointmentRef.current.appointmentId === updatedAppointment.appointmentId) {
        //   setAppointment(updatedAppointment);
        // }
    }


    return (
        <>
            <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
                <Toolbar />
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item>
                        <h1>Appointments</h1>
                    </Grid>
                    <Grid item>
                        <Button color="primary" variant="contained" onClick={() => history("/appointments/new")} >New appointment</Button>
                    </Grid>
                </Grid>
                <Grid container direction="column" >
                    <Grid item>
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Appointment date</TableCell>
                                        <TableCell>Client firm</TableCell>
                                        <TableCell>Appointment type</TableCell>
                                        <TableCell>Nature of appointment</TableCell>
                                        <TableCell>Appointees</TableCell>
                                        <TableCell>Casecode</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        appointments?.map((appointment, index) => (
                                            <TableRow key={appointment.appointmentId}>
                                                <TableCell>
                                                    <RouterLink to={"/appointments/" + appointment.appointmentId}>
                                                        {appointment.appointmentName}
                                                    </RouterLink>
                                                </TableCell>
                                                <TableCell>{intlFormat(new Date(appointment.appointmentDate), dateFormat)}</TableCell>
                                                <TableCell>{appointment.clientFirm?.displayName}</TableCell>
                                                <TableCell>{appointment.appointmentType}</TableCell>
                                                <TableCell>{appointment.natureOfAppointment}</TableCell>
                                                <TableCell>
                                                    {appointment.appointees != undefined &&
                                                        appointment.appointees.map((appointee, index) => (
                                                            <span>{appointee.contactId}, </span>
                                                        ))
                                                    }
                                                </TableCell>
                                                <TableCell>{appointment.casecode}</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Box>
        </>
    )

}

export default AppointmentDashboard