import { Grid, Typography } from '@mui/material';
import React from 'react';
import parse from 'html-react-parser';

export const TypographyComponent = ({ item, questions, reactHookFormMethods }) => {

    return (
        <Grid item xs margin={1}>
            <Typography variant={item.variant}>{parse(item.content)}</Typography>
        </Grid>
    )
}