import {  Grid } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import { useNavigate , useParams } from 'react-router';
import { authProvider } from '../../../authProvider';
import useRSocket from '../../../lib/useRSocket';

const contactManagementUrl = "ws://localhost:6090";

const navigate = (history, data) => {
    history("/contactmanagement/organisations/")
}

export const ActionComponent = ({ item, questions, reactHookFormMethods }) => {

    const { handleSubmit, reset } = { ...reactHookFormMethods }

    const [socket, client, connected, isError, sendRequestResponse, sendRequestStream] = useRSocket(contactManagementUrl);
    const history = useNavigate ();
    const params = useParams();

    const performAction = (actionDetails, formData) => {

        switch (item.action) {
            case "ACTION":
                let payload = { ...formData };

                if (actionDetails?.paramValues) {
                    Object.keys(actionDetails?.paramValues).forEach(key => {
                        payload = { ...payload, [key]: params[key] }
                    })
                }

                console.log("Payload", payload)

                authProvider.getIdToken().then(token => {
                    sendRequestResponse(token.idToken.rawIdToken, actionDetails.targetEndPoint, payload, (data) => navigate(history, data), (error) => console.log(actionDetails + " onError: " + String(error)), (sub) => console.log(actionDetails + " onSubscribe"))
                })
                return;
            case "CANCEL":
                history.goBack();
            case "RESET":
                reset();
            default:
                break;
        }

    }

    return (

        <Grid item margin={1}>
            <Button {...item.actionProps} onClick={handleSubmit(data => performAction(item.actionDetails, data))}>{item.label}</Button>
        </Grid>
    )
}