import { Grid } from '@mui/material';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useProposalState } from '../../../components/Policy/Context/ProposalContext';
import { AutocompleteComponent, DynamicAutocompleteComponent } from './AutocompleteComponent';
import { DateComponent } from './DateComponent';
import { NumberFieldComponent } from './NumberFieldComponent';
import { RadioComponent } from './RadioComponent';
import { SelectComponent } from './SelectComponent';
import { SwitchComponent } from './SwitchComponent';
import { TextFieldComponent } from './TextFieldComponent';

export const QuestionComponent = ({ item, questions, questionSets, reactHookFormMethods }) => {

    const question = questions[item.question]
    const hasAskWhen = question.askWhen ? true : false;
    const enableWhenWatchFields = question.askWhen ? reactHookFormMethods.watch("questionAnswers." + question.askWhen?.questionKey) : null
    const [enabledField, setEnabled] = useState(true);

    useEffect(() => {
        let enable = true;
        const enableWhenValue = question.askWhen?.questionValues ? question.askWhen.questionValues : []

        if (hasAskWhen && !enableWhenValue.includes(enableWhenWatchFields)) {
            enable = false;
        }

        if (enable) {
            setEnabled(true)
        } else {
            setEnabled(false)
        }
    }, [enableWhenWatchFields, question.askWhen, hasAskWhen])

    if (enabledField === false || !questionSets?.includes(item.question)) {
        return <Grid item xs margin={1} />;
    }

    return (
        <Grid item xs {...item.sizeProps} padding={1}>
            <RenderQuestionComponent item={item} questions={questions} reactHookFormMethods={reactHookFormMethods} />
        </Grid>
    )
}



const RenderQuestionComponent = ({ item, questions, reactHookFormMethods }) => {

    const question = questions[item.question]
    const formName = ( item.level? item.level + "." : "" ) + ( item.formKey? item.formKey + "." : "" ) + question.key;

    const proposalState = useProposalState();
    const defaultAnswer = get(proposalState?.proposal, formName, (question?.defaultValue ? question.defaultValue : ""));


    if (question === undefined || question === null) {
        return (
            <></>
        )
    }

    switch (question?.type) {
        case 'TEXT':
            return <TextFieldComponent item={item} questions={questions} reactHookFormMethods={reactHookFormMethods} defaultAnswer={defaultAnswer} />
        case 'YES_NO':
            return <RadioComponent item={item} questions={questions} reactHookFormMethods={reactHookFormMethods} defaultAnswer={defaultAnswer} />
        case 'YES_NO_UNKNOWN':
            return <RadioComponent item={item} questions={questions} reactHookFormMethods={reactHookFormMethods} includeUnknown={true} defaultAnswer={defaultAnswer} />
        case 'DATE':
            return <DateComponent item={item} questions={questions} reactHookFormMethods={reactHookFormMethods} defaultAnswer={defaultAnswer} />
        case 'SELECT':
            return <SelectComponent item={item} questions={questions} reactHookFormMethods={reactHookFormMethods} defaultAnswer={defaultAnswer} />
        case 'SWITCH':
            return <SwitchComponent item={item} questions={questions} reactHookFormMethods={reactHookFormMethods} defaultAnswer={defaultAnswer} />
        case 'AUTOCOMPLETE':
            return <DynamicAutocompleteComponent item={item} questions={questions} reactHookFormMethods={reactHookFormMethods} defaultAnswer={defaultAnswer} />
        case 'NUMBER':
            return <NumberFieldComponent item={item} questions={questions} reactHookFormMethods={reactHookFormMethods} defaultAnswer={defaultAnswer} />
        default:
            return (
                <TextFieldComponent item={item} questions={questions} reactHookFormMethods={reactHookFormMethods} defaultAnswer={defaultAnswer} />
            )
    }
}