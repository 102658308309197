import DateFnsUtils from '@date-io/date-fns';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';

import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import useState from 'react-usestateref'
import { authProvider } from '../../authProvider';
import useRSocket from '../../lib/useRSocket';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useNavigate, useParams } from 'react-router-dom';

const defaultValues = {
    clientFirm: {
        contactId: "",
        displayName: ""
    },
    clientOffice: {
        contactId: "",
        displayName: ""
    },
    appointmentName: "",
    appointmentDate: null,
    notYetAppointed: false,
    appointmentType: "",
    natureOfAppointment: "",
    appointees: [],
    casecode: "",
    joint: false
}

const appointmentUrl = "ws://localhost:6110";
const contactManagementUrl = "ws://localhost:6090";

const schema = yup.object().shape({
    clientFirm: yup.object().shape({
        contactId: yup.string().required("Client firm is required")
    }),
    clientOffice: yup.object().shape({
        contactId: yup.string().required("Client office is required")
    }),
    appointmentName: yup.string().required("Appointment name is required"),
    appointmentDate: yup.date().required("Appointment date is requried").nullable(),
    appointmentType: yup.string().required("Appointment type is required"),
    natureOfAppointment: yup.string().required("Nature of appointment is required"),
});

export const AppointmentView = () => {

    const { appointmentId } = useParams();

    const [appointment, setAppointment] = useState(defaultValues);
    const [loadingAppointment, setLoadingAppointment] = useState(true);

    const [socket, client, connected, isError, sendRequestResponse, sendRequestStream] = useRSocket(appointmentUrl);


    const appointmentLoaded = (data) => {
        setAppointment(data);
        setLoadingAppointment(false);
    }

    useEffect(() => {
        if (connected && loadingAppointment) {
            authProvider.getIdToken().then(token => {
                sendRequestResponse(token.idToken.rawIdToken, "get.appointment", { appointmentId: appointmentId }, (data) => appointmentLoaded(data), (error) => console.log("createAppointment onError: " + String(error)), (sub) => console.log("createAppointment onSubscribe"))
            })
        }
    }, [appointmentId, connected])

    if (loadingAppointment) {
        return (
            <p>Loading appointment</p>
        )
    } else {
        return (
            <Grid container >
                <Grid item xs={12} md={6} padding={2}>
                    <Paper>
                        <Grid container>
                            <Grid item xs={12} padding={2}>
                                <Typography variant="h4" >View appointment</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} padding={2}>
                                <FormControl fullWidth={true}>
                                    <FormLabel>Client firm</FormLabel>
                                    <TextField disabled value={appointment.clientFirm?.displayName} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} padding={2}>
                                <FormControl fullWidth={true}>
                                    <FormLabel>Client office</FormLabel>
                                    <TextField disabled value={appointment.clientOffice?.displayName} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} padding={2}>
                                <FormControl fullWidth={true} >
                                    <FormLabel>Appointment name</FormLabel>
                                    <TextField disabled value={appointment.appointmentName} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} padding={2}>
                                <FormControl fullWidth={true}>
                                    <FormLabel>Appointment date</FormLabel>
                                    <TextField disabled value={appointment.appointmentDate} />
                                </FormControl>
                            </Grid>
                            <Grid container alignItems="center" item xs={12} sm={6} padding={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            disabled
                                            checked={appointment.notYetAppointed}
                                        />
                                    }
                                    label="Not yet appointed"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} padding={2}>
                                <FormControl fullWidth={true} variant="outlined">
                                    <FormLabel>Appointment type</FormLabel>
                                    <TextField disabled value={appointment.appointmentType} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} padding={2}>
                                <FormControl fullWidth={true} variant="outlined">
                                    <FormLabel>Nature of appointment</FormLabel>
                                    <TextField disabled value={appointment.natureOfAppointment} />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} padding={2}>
                                <Typography variant="h5">Appointees</Typography>

                                <TableContainer component={Paper}>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {appointment.appointees &&
                                                appointment.appointees.map((appointee, index) => (
                                                    <TableRow key={appointee.contactId}>
                                                        <TableCell>{appointee.contactId}</TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>

                            <Grid item xs={12} sm={6} padding={2}>
                                <FormControl fullWidth={true}>
                                    <FormLabel>Case code</FormLabel>
                                    <TextField disabled value={appointment.casecode} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} padding={2} container alignItems="center">

                                <FormControlLabel
                                    control={
                                        <Switch
                                            color="primary"
                                            disabled
                                            checked={appointment.jointAppointment}
                                        />
                                    }
                                    label="Joint appointment"
                                />

                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} padding={2}></Grid>
            </Grid>
        )
    }
}

export const AppointmentForm = () => {

    const { register, handleSubmit, control, reset, setValue, watch, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaultValues
    });

    const [socket, client, connected, isError, sendRequestResponse, sendRequestStream] = useRSocket(appointmentUrl);
    const history = useNavigate();
    const [socketCM, clientCM, connectedCM, isErrorCM, sendRequestResponseCM, sendRequestStreamCM] = useRSocket(contactManagementUrl);
    const [clientFirms, setClientFirms, clientFirmsRef] = useState([]);
    const [clientOffices, setClientOffices, clientOfficesRef] = useState({});
    const [appointees, setAppointees, appointeesRef] = useState(defaultValues.appointees)
    const [availableAppointees, setAvailableAppointees, availableAppointeesRef] = useState(["Andrew Tacchi", "William Matthews", "Jayne Reeves"])


    const onSubmit = data => {
        const formData = { ...data, appointees: appointeesRef.current }
        console.log(formData);
        createAppointment(formData)
    }


    useEffect(() => {
        if (connectedCM) {
            authProvider.getIdToken().then(token => {
                sendRequestResponseCM(token.idToken.rawIdToken, "get.placing.broker.organisations", {}, (data) => setClientFirms(data), (error) => console.log("get.placing.broker.organisation onError: " + String(error)), (sub) => console.log("createAppointment onSubscribe"))
            })
        }
    }, [connectedCM]);


    const handleReset = () => {
        setAppointees(defaultValues.appointees);
        reset();
    }

    const handleChange = (event) => {
        const newAppointees = [...appointees];
        newAppointees.push(event.target.value);
        setAppointees(newAppointees)

        const newAvailableAppointees = [...availableAppointees];
        var index = newAvailableAppointees.indexOf(event.target.value)
        if (index !== -1) {
            newAvailableAppointees.splice(index, 1);
        }
        setAvailableAppointees(newAvailableAppointees);
    }

    const removeAppointee = (appointee) => {

        const newAppointees = [...appointees];
        var index = newAppointees.indexOf(appointee)
        if (index !== -1) {
            newAppointees.splice(index, 1);
        }
        setAppointees(newAppointees)

        const newAvailableAppointees = [...availableAppointees];
        newAvailableAppointees.push(appointee);
        setAvailableAppointees(newAvailableAppointees);
    }

    function createAppointment(payload) {
        console.log(payload)
        authProvider.getIdToken().then(token => {
            sendRequestResponse(token.idToken.rawIdToken, "create.appointment", payload, (data) => navigateToViewAppointment(data.data.toString()), (error) => console.log("createAppointment onError: " + String(error)), (sub) => console.log("createAppointment onSubscribe"))
        })
    }

    const navigateToViewAppointment = (appointmentId) => {
        alert(appointmentId)
        history("/appointments/" + appointmentId)
    }

    const handleOnChange = (date) => {
        // if (Date.parse(date)) {
        // handleDateChange(date)
        setValue("appointmentDate", date, true)
        // }
        // else {
        // handleDateChange(null)
        // setValue("appointmentDate", null, false)
        // }
    }

    const handleChangeClientFirm = (event) => {
        const index = clientFirmsRef.current.findIndex(clientFirm => clientFirm.organisationId === event.target.value)
        register("clientFirm.displayName")
        register("clientOffice.displayName")
        if (index === -1) {
            setValue("clientFirm", { contactId: "", displayName: "" })
            setValue("clientOffice", { contactId: "", displayName: "" })
            // setValue("clientOffice.contactId", "")
            // setValue("clientOffice.displayName", "")
            // setValue("clientFirm.contactId", "")
            // setValue("clientFirm.displayName", "")
        } else {
            console.log(clientFirms[index].teams)
            setClientOffices(clientFirms[index].teams)
            setValue("clientFirm", { contactId: event.target.value, displayName: clientFirms[index].name })
            setValue("clientOffice", { contactId: "", displayName: "" })

            // setValue("clientOffice.contactId", "")
            // setValue("clientOffice.displayName", "")
            // setValue("clientFirm.contactId", event.target.value)
            // setValue("clientFirm.displayName", clientFirms[index].name)
        }
    }

    const handleChangeClientOffice = (event) => {
        // alert(event.target.value)
        const clientOffice = clientOffices[event.target.value];
        console.log(clientOffice)
        if (clientOffice === undefined) {
            setValue("clientOffice", { contactId: "", displayName: "" })
            // setValue("clientOffice.contactId", "")
            // setValue("clientOffice.displayName", "")
        } else {
            console.log(clientOffice)
            setValue("clientOffice", { contactId: clientOffice.teamId, displayName: clientOffice.name })
            // setValue("clientOffice.contactId", clientOffice.teamId)
            // setValue("clientOffice.displayName", clientOffice.name)
        }
    }

    return (
        <Grid container >
            <Grid item xs={12} md={6} padding={2}>
                <Paper>
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                        <Grid container>
                            <Grid item xs={12} padding={2}>
                                <Typography variant="h4" >New appointment</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} padding={2}>
                                <FormControl fullWidth={true}
                                    variant="outlined"
                                    error={errors.clientFirm ? true : false}
                                >
                                    <FormLabel>Client firm</FormLabel>
                                    <Controller
                                        render={({ field }) => (
                                            <Select {...field} onChange={(e) => handleChangeClientFirm(e)}>
                                                <MenuItem value="">
                                                    <em>Please select...</em>
                                                </MenuItem>
                                                {
                                                    clientFirms.map((firm) => (
                                                        <MenuItem key={firm.organisationId} value={firm.organisationId}>{firm.name}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        )}
                                        name="clientFirm.contactId"
                                        control={control}
                                    />
                                    <FormHelperText>{errors.clientFirm?.contactId?.message}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} padding={2}>
                                <FormControl fullWidth={true}
                                    variant="outlined"
                                    error={errors.clientOffice ? true : false}
                                >
                                    <FormLabel>Client office</FormLabel>
                                    <Controller
                                        render={({ field }) => (
                                            <Select {...field} onChange={(e) => handleChangeClientOffice(e)}>
                                                <MenuItem value="">
                                                    <em>Please select...</em>
                                                </MenuItem>
                                                {
                                                    Object.keys(clientOffices).map(key => (
                                                        <MenuItem key={clientOffices[key].teamId} value={clientOffices[key].teamId}>{clientOffices[key].name}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        )}
                                        name="clientOffice.contactId"
                                        control={control}
                                    />

                                    {/* <Controller
                                        render={({ field }) => <TextField {...field} />}
                                        name="clientOfficeId"
                                        control={control}
                                    /> */}
                                    <FormHelperText>{errors.clientOffice?.contactId?.message}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} padding={2}>
                                <FormControl fullWidth={true}
                                    error={errors.appointmentName ? true : false}
                                >
                                    <FormLabel>Appointment name</FormLabel>
                                    <Controller
                                        render={({ field }) => <TextField {...field} />}
                                        name="appointmentName"
                                        control={control}
                                    />
                                    <FormHelperText>{errors.appointmentName?.message}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} padding={2}>
                                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <FormControl fullWidth={true}
                                        error={errors.appointmentDate ? true : false}
                                    >
                                        <FormLabel>Appointment date</FormLabel>

                                        <Controller
                                            name="appointmentDate"
                                            control={control}
                                            render={({ field: { ref, ...rest } }) => (
                                                <KeyboardDatePicker
                                                    input={true}
                                                    showTodayButton={true}
                                                    disableFuture={true}
                                                    clearable
                                                    placeholder="dd/mm/yyyy"
                                                    id="appointmentDateId"
                                                    format="dd/MM/yyyy"
                                                    onChange={date => handleOnChange(date)}
                                                    KeyboardButtonProps={{
                                                        "aria-label": "change date"
                                                    }}
                                                    {...rest}
                                                />
                                            )}
                                        />
                                        <FormHelperText>{errors.appointmentDate?.message}</FormHelperText>
                                    </FormControl>
                                </MuiPickersUtilsProvider> */}
                            </Grid>
                            <Grid container alignItems="center" item xs={12} sm={6} padding={2}>

                                <Controller
                                    name="notYetAppointed"
                                    control={control}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                    checked={field.value}
                                                />
                                            }
                                            label="Not yet appointed"
                                        />
                                    )}
                                />

                            </Grid>
                            <Grid item xs={12} sm={6} padding={2}>
                                <FormControl fullWidth={true} variant="outlined"
                                    error={errors.appointmentType ? true : false}
                                >
                                    <FormLabel>Appointment type</FormLabel>
                                    <Controller
                                        name="appointmentType"
                                        control={control}
                                        render={({ field }) => (
                                            <Select  {...field}>
                                                <MenuItem value="">
                                                    <em>Please select...</em>
                                                </MenuItem>
                                                <MenuItem value="Bankruptcy">Bankruptcy</MenuItem>
                                            </Select>
                                        )}
                                    />
                                    <FormHelperText>{errors.appointmentType?.message}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} padding={2}>
                                <FormControl fullWidth={true} variant="outlined"
                                    error={errors.natureOfAppointment ? true : false}
                                >
                                    <FormLabel>Nature of appointment</FormLabel>
                                    <Controller
                                        name="natureOfAppointment"
                                        control={control}
                                        render={({ field }) => (
                                            <Select  {...field}>
                                                <MenuItem value="">
                                                    <em>Please select...</em>
                                                </MenuItem>
                                                <MenuItem value="Monitor">Monitor</MenuItem>
                                            </Select>
                                        )}
                                    />
                                    <FormHelperText>{errors.natureOfAppointment?.message}</FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} padding={2}>
                                <Typography variant="h5">Appointees</Typography>
                                <AvailableAppointees availableAppointees={availableAppointees} onChange={handleChange} control={control}></AvailableAppointees>
                                <TableContainer component={Paper}>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                appointees.map((appointee, index) => (
                                                    <TableRow key={appointee}>
                                                        <TableCell>{appointee}</TableCell>
                                                        <TableCell><Button onClick={(e) => removeAppointee(appointee)}>remove</Button></TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>

                            <Grid item xs={12} sm={6} padding={2}>
                                <FormControl fullWidth={true}>
                                    <FormLabel>Case code</FormLabel>
                                    <Controller
                                        render={({ field }) => <TextField {...field} />}
                                        name="casecode"
                                        control={control}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} padding={2} container alignItems="center">
                                <Controller
                                    name="joint"
                                    control={control}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    color="primary"
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                    checked={field.value}
                                                />
                                            }
                                            label="Joint appointment"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6} padding={2}>
                                <Button color='secondary' onClick={handleReset}>Cancel</Button>
                            </Grid>
                            <Grid item xs={6} padding={2} >
                                <Grid container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center" >
                                    <Button variant="contained" color='primary' type="submit">Save</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Grid>
        </Grid >
    )

}

const AvailableAppointees = ({ availableAppointees, onChange, control }) => {
    if (availableAppointees !== undefined && availableAppointees.length > 0) {
        return (
            <FormControl fullWidth={true} variant="outlined">
                <FormLabel>Add appointee(s)</FormLabel>
                <Select value=""
                    // {...field}
                    onChange={onChange}>
                    {
                        availableAppointees.map((appointee) => (
                            <MenuItem key={appointee} value={appointee}>{appointee}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl >
        )
    } else {
        return (
            <p>No more available appointees</p>
        )
    }
}


export default AppointmentForm