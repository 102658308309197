
import { useCallback, useEffect } from "react";
import { authProvider } from '../../authProvider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { Card, CardContent, Grid, Toolbar, Typography } from "@mui/material";
import useRSocket from "../../lib/useRSocket";
import useState from 'react-usestateref'
import ActivityContacts from "./ActivityContacts";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { intlFormat } from "date-fns";
import { Box } from "@mui/system";
import useRSocket2 from "../../lib/useRSocket2";

const wsUrl = "ws://localhost:6080";

const dateTimeFormat = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric'
}

function Activities() {
  const [events, setEvents, eventsRef] = useState([]);
  const [activity, setActivity, activityRef] = useState({});
  const [watchCount, setWatchCount] = useState(0);
  const [assignedCount, setAssignedCount] = useState(0);

  const setEventsCallback = useCallback((data) => {
    console.log("callback to set Events");
    setEvents(data)
  }, [setEvents])

  const updateActivity = useCallback((updatedActivity) => {
    const index = eventsRef.current.findIndex(act => act.activityId === updatedActivity.activityId)
    let updatedEvents = [...eventsRef.current];
    updatedEvents[index] = updatedActivity;
    console.log(index)
    console.log(updatedActivity)
    console.log(updatedEvents)
    setEvents(updatedEvents);

    if (activityRef.current.activityId === updatedActivity.activityId) {
      setActivity(updatedActivity);
    }
  }, [activityRef, eventsRef, setActivity, setEvents])

  const [socket, client, connected, isError, sendRequestResponse, sendRequestStream] = useRSocket2(wsUrl);

  function watchEvent(payload) {
    authProvider.getIdToken().then(token => {
      sendRequestResponse(socket, token.idToken.rawIdToken, "watch.activity", payload, (data) => console.log("watched"), (error) => console.log("watchEvent onError: " + String(error)), (sub) => console.log("watchEvent onSubscribe"))
    })
  }

  function unwatchEvent(payload) {
    authProvider.getIdToken().then(token => {
      sendRequestResponse(socket, token.idToken.rawIdToken, "unwatch.activity", payload, (data) => console.log("unwatched"), (error) => console.log("unwatchEvent onError: " + String(error)), (sub) => console.log("unwatchEvent onSubscribe"))
    })
  }

  function assignEvent(payload) {
    authProvider.getIdToken().then(token => {
      sendRequestResponse(socket, token.idToken.rawIdToken, "assign.activity", payload, (data) => loadActivities(), (error) => console.log("assignEvent onError: " + String(error)), (sub) => console.log("assignEvent onSubscribe"))
    })
  }

  const loadActivities = useCallback(() => {
    authProvider.getIdToken().then(token => {
      sendRequestResponse(socket, token.idToken.rawIdToken, "get.activities", {}, setEventsCallback, (error) => console.log("compositeResponseRequest onError: " + String(error)), (sub) => console.log("compositeResponseRequest onSubscribe"))
    })
  }, [sendRequestResponse, setEventsCallback, socket]);

  const loadActivitiesSubscription = useCallback(() => {
    authProvider.getIdToken().then(token => {
      sendRequestStream(socket, token.idToken.rawIdToken, "get.activities.subscribe", {}, (data) => console.log(data), (data) => updateActivity(data), (error) => console.log("loadActivitiesSubscription onError: " + String(error)), (sub) => console.log("loadActivitiesSubscription onSubscribe"))
    })
  }, [sendRequestStream, socket, updateActivity]);

  const loadActivity = useCallback((payload) => {
    authProvider.getIdToken().then(token => {
      sendRequestResponse(socket, token.idToken.rawIdToken, "get.activity", payload, (data) => setActivity(data), (error) => console.log("loadActivity onError: " + String(error)), (sub) => console.log("loadActivity onSubscribe"))
    })
  }, [sendRequestResponse, setActivity, socket]);

  const getActivityWatchCount = useCallback((payload) => {
    authProvider.getIdToken().then(token => {
      sendRequestStream(socket, token.idToken.rawIdToken, "get.activity.watch.count", payload, (data) => setWatchCount(data), (data) => setWatchCount(data), (error) => console.log("getActivityWatchCount onError: " + String(error)), (sub) => console.log("getActivityWatchCount onSubscribe"))
    })
  }, [sendRequestStream, socket]);

  const getActivityAssignedCount = useCallback((payload) => {
    authProvider.getIdToken().then(token => {
      sendRequestStream(socket, token.idToken.rawIdToken, "get.activity.assigned.count", payload, (data) => setAssignedCount(data), (data) => setAssignedCount(data), (error) => console.log("getActivityWatchCount onError: " + String(error)), (sub) => console.log("getActivityWatchCount onSubscribe"))
    })
  }, [sendRequestStream, socket]);

  useEffect(() => {

    if (connected && socket != null) {
      console.log( "loading activities")
      loadActivities();
      loadActivitiesSubscription();
      getActivityWatchCount({ currentUser: "andrew.tacchi@xaveric.co.uk" });
      getActivityAssignedCount({ currentUser: "andrew.tacchi@xaveric.co.uk" });
    }
  }, [connected, socket, loadActivities, loadActivitiesSubscription, getActivityWatchCount, getActivityAssignedCount])

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <div>Connectiion status Connected: {String(connected)}, Error: {String(isError)} </div>
        <button onClick={() => loadActivities()}>Manual refresh</button>

        <Grid container paddingBottom={2} paddingTop={2}>
          <Grid item>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {watchCount}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Watching
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {assignedCount}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Assigned to me
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container >
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Start on</TableCell>
                  <TableCell>Due by</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Topic</TableCell>
                  <TableCell>Assigned to</TableCell>
                  <TableCell>Watch</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  events.map((event, index) => (
                    <TableRow key={event.activityId}>
                      <TableCell>{event.displayName} <button onClick={() => loadActivity({ activityId: event.activityId })} >Show</button></TableCell>
                      <TableCell>{intlFormat(new Date(event.handling.startHandlingDate), dateTimeFormat)}</TableCell>
                      <TableCell>{intlFormat(new Date(event.handling.dueDate), dateTimeFormat)}</TableCell>
                      <TableCell>{String(event.status)}</TableCell>
                      <TableCell>{event.topic}</TableCell>
                      <TableCell><ActivityContacts currentContact={event.assignedTo.username} organisationId={"6069f0d1676e11249d949fe9"} activityId={event.activityId} assignActivityCallback={assignEvent} />
                        {/* {event.assignedTo.username} <TextField id={"assignTo_" + index} defaultValue={event.assignedTo.username}></TextField> <button onClick={() => assignEvent(socket, { activityId: event.activityId, username: document.getElementById("assignTo_" + index).value })} >Assign</button>  */}
                      </TableCell>
                      <TableCell>
                        {!event.watching &&
                          <Button onClick={() => watchEvent({ id: event.activityId })} ><VisibilityOffOutlinedIcon color="disabled" /></Button>
                        }
                        {event.watching &&
                          <Button onClick={() => unwatchEvent({ id: event.activityId })} ><VisibilityIcon /></Button>
                        }
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>

          <pre>{JSON.stringify(activity, null, 2)}</pre>

        </Grid>
        {/* <pre>{JSON.stringify(client, null, 2)}</pre> */}
        {/* <pre>{JSON.stringify(socket, null, 2)}</pre> */}
        {/* <pre>{JSON.stringify(events, null, 2)}</pre> */}
      </Box>
    </>
  )

}

export default Activities;
