import React, { useEffect } from 'react';
import useState from 'react-usestateref'
import useRSocket from '../../lib/useRSocket';
import { authProvider } from '../../authProvider';
import {  FormControl, MenuItem, Select } from '@mui/material';
import Button from '@mui/material/Button';

const wsUrl = "ws://localhost:6090/rsocket-contact-management";

const ActivityContacts = ({ currentContact, organisationId, activityId, assignActivityCallback }) => {

    const [socket, client, connected, isError, sendRequestResponse, sendRequestStream] = useRSocket(wsUrl);
    const [contacts, setContacts] = useState([]);
    const [showContactDropdown, setShowContactDropdown] = useState(false);
    const [newUser, setNewUser, newUserRef] = useState(currentContact);

    useEffect(() => {
        if (connected) {

            authProvider.getIdToken().then(token => {
                sendRequestResponse(socket, token.idToken.rawIdToken, "get.organisation.members", { organisationId: organisationId }, (data) => setContacts(data), (error) => console.log("ActivityContacts onError: " + String(error)), (sub) => console.log("ActivityContacts onSubscribe"))
            })

        }
    }, [connected])

    const assginActivity = () => {
        assignActivityCallback({ username: newUserRef.current, activityId: activityId });
        setShowContactDropdown(false)
    }

    const handleCloseDialog = () => {
        setNewUser(currentContact)
        setShowContactDropdown(false)
    }

    if (showContactDropdown) {
        return (
            <>
                {/* <p onClick={() => setShowContactDropdown(true)}>{currentContact}</p> */}
                {/* <Dialog disableBackdropClick disableEscapeKeyDown open={showContactDropdown}> */}
                {/* <DialogTitle>Assign to</DialogTitle> */}
                {/* <DialogContent> */}

                <FormControl fullWidth={true}>
                    {/* <InputLabel>Team member</InputLabel> */}
                    <Select value={newUser} onChange={(e) => setNewUser(e.target.value)}>
                        <MenuItem value="">
                            <em>Please select...</em>
                        </MenuItem>
                        {contacts.map((contact) => {
                            return (
                                <MenuItem key={contact.username} value={contact.username || ''}>{contact.username}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <Button color='secondary' onClick={() => handleCloseDialog()} >Cancel</Button>
                <Button color='primary' onClick={() => assginActivity()} >Ok</Button>
                {/* </DialogContent> */}
                {/* </Dialog> */}
            </>
        )
    } else {
        return <p onClick={() => setShowContactDropdown(true)}>{currentContact}</p>
    }
}

export default ActivityContacts