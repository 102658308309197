import { FormControl, FormHelperText, FormLabel, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import * as R from 'ramda';

const useFilterOptions = (options, filter, watch, setValue, questionKey) => {

    const [filteredOptions, setFilteredOptions] = useState(options)
    const watchedValue = watch("questionAnswers." + filter.question)
    const currentQuestionValue = watch(questionKey)

    useEffect(() => {

        if (filter === undefined || R.isEmpty(filter)) {
            setFilteredOptions(options);
            return
        }

        if (watchedValue === undefined || watchedValue === "") {
            if (filter.default === "showNone") {
                setValue(questionKey, "", {
                    shouldValidate: true,
                    shouldDirty: true
                })
                setFilteredOptions([]);
            } else {
                setFilteredOptions(options);
            }
            return;
        }

        const filteredOptionsValues = filter.questionValues[watchedValue] ? filter.questionValues[watchedValue] : []

        const newFilteredOptions = options.filter(item => filteredOptionsValues.includes(item.value))
        if (filteredOptionsValues !== undefined && !filteredOptionsValues.includes(currentQuestionValue)) {
            setValue(questionKey, "", {
                shouldValidate: true,
                shouldDirty: true
            })
        }
        setFilteredOptions(newFilteredOptions)

        return () => {
            setFilteredOptions(options)
        }
    }, [filter, options, watchedValue, setValue, questionKey, currentQuestionValue])

    return filteredOptions
}

export const SelectComponent = ({ item, questions, reactHookFormMethods }) => {

    const { control, watch, setValue, register } = { ...reactHookFormMethods }
    const question = questions[item.question]
    const formName = ( item.level? item.level + "." : "" ) + ( item.formKey? item.formKey + "." : "" ) + question.key;

    const filter = question.optionsProps?.filter ? question.optionsProps.filter : {};

    const filteredOptions = useFilterOptions(question?.optionsProps?.options, filter, watch, setValue, formName)

    return (
        <>
            <FormControl fullWidth={true}
            >
                <FormLabel>{question.label}</FormLabel>
                <Controller
                    render={({ field }) => (
                        <Select {...field} size="small" >
                            <MenuItem value="">
                                <em>Please select...</em>
                            </MenuItem>
                            {
                                filteredOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                ))
                            }
                        </Select>
                    )}
                    name={formName}
                    control={control}
                    defaultValue=""
                />
                <FormHelperText>{question.helpText}</FormHelperText>
            </FormControl>
        </>
    )
}