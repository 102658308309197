import React from 'react';
import { Routes, Route, useMatch, Outlet, Navigate } from 'react-router-dom';
import { ProductProvider } from '../../Product/ProductContext';
import { productReducer } from '../../Product/ProductReducer';
import { ProposalProvider } from '../Context/ProposalContext';
import { CreateProposal } from '../CreateProposal';
import { EditPolicyLevelTabPanel, EditProposal } from './EditProposal';
import { StandardProposalLayout } from './Layout/StandardProposalLayout';
import { Box } from "@mui/system"
import { Typography, Toolbar } from "@mui/material"
import { EditPageLayout } from './EditPageLayout';
import { CreateProposalLayout } from './Layout/CreateProposalLayout';
import { EditProposalLineOfBusiness, EditProposalLineOfBusinessPanel } from './EditProposalLineOfBusiness';
import { EditProposalCoverRiskDetails } from './EditProposalCoverRiskDetails';
import { ProposalSubscriptor } from './ProposalSubscriptor';
import { proposalReducer } from '../Context/ProposalReducer';
import { ProposalDashboard, ProposalDashboardLayout } from './ProposalDashboard';

const ProposalRoutes = () => {
    // const { path } = useMatch();

    return (
        <>
            <Routes>
                {/* <Route exact path="/dashboard" element={
                <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
                    <Toolbar />
                    <Typography variant='h4'>Dashboard screen</Typography>
                    <Typography variant='body1'>Dashboard screen</Typography>
                </Box>
            }
            />
            <Route exact path="create" element={
                <ProductProvider reducer={productReducer} productId="PSC">
                    <CreateProposalLayout>
                        <CreateProposal productId="PSC" />
                    </CreateProposalLayout>
                </ProductProvider>
            }
            />
           */}
                {/* <Redirect
                exact strict
                from={`${path}/:proposalId/edit`}
                to={`${path}/:proposalId/edit/`}
            /> */}
                <Route path=":proposalId/dashboard" element={<ProposalDashboardLayout />} />
                <Route path=":proposalId/edit/*" element={<EditPageLayout />} >
                    <Route path="pages/:pageName" element={<EditPolicyLevelTabPanel productVersion={1} />} />
                    <Route path="lobs/:lobName/*" element={<EditProposalLineOfBusiness productVersion={1} />} >
                        <Route path="pages/:pageName" element={<EditProposalLineOfBusinessPanel productVersion={1} />} />
                        <Route path="covers/:coverName" element={<EditProposalCoverRiskDetails productVersion={1} />} />
                        <Route path="*" element={<Navigate to={"pages/lobDetails"} />} />
                    </Route>
                    <Route path="*" element={<Navigate to={"pages/generalQuoteDetails"} />} />
                </Route>
                <Route exact path="create" element={
                    <ProductProvider reducer={productReducer} productId="PSC">
                        <CreateProposalLayout>
                            <CreateProposal productId="PSC" />
                        </CreateProposalLayout>
                    </ProductProvider>
                }
                />
                {/* <Route path=":proposalId/edit" element={<div><h1>edit</h1><h1>edit</h1><h1>edit</h1></div>} /> */}
                {/* <Route path="test" element={<h1>test</h1>} /> */}
                {/* <Redirect
                exact
                from={path}
                to={`${path}/dashboard`}
            />
            <Redirect to={`${path}/dashboard`} /> */}
            </Routes >

        </>
    )
}

export default ProposalRoutes;