import React from 'react';
import { Outlet } from 'react-router-dom';
import ProposalRoutes from './Routes';

const ProposalModule = () => {
    return (
        <>
            <ProposalRoutes />
        </>
    )
}

export default ProposalModule;
