// authProvider.js
    import { MsalAuthProvider, LoginType } from 'react-aad-msal';
// Msal Configurations
const config = {
    auth: {
        authority: `${process.env.REACT_APP_GPPAS_AUTHORITY}`,
        clientId: `${process.env.REACT_APP_GPPAS_CLIENT_ID}`,
        postLogoutRedirectUri: window.location.origin,
        redirectUri: `${process.env.REACT_APP_GPPAS_REDIRECT_LOGIN_URL}`,
        validateAuthority: true,
        // After being redirected to the "redirectUri" page, should user
        // be redirected back to the Url where their login originated from?
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    }
};
// Authentication Parameters
export const authenticationParameters = {
    scopes: [
        `User.Read`
    ]
}
export const authenticationParametersGraph = {
    scopes: [
        'openid'
    ]
}
// Options
export const options = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: window.location.origin
}
export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)