import { Drawer, Toolbar, Typography } from "@mui/material"
import { Box } from "@mui/system"
import AzureAD from "react-aad-msal"
import { authProvider } from "../../../../authProvider"
import PropTypes from 'prop-types';
import { ProposalSidebar } from "../ProposalSidebar";

const drawerWidth = 340;

export const StandardProposalLayout = ({ children }) => {

    return (
        <>
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}
            >
                <Toolbar />
                <Box sx={{ overflow: 'auto' }}>
                    <ProposalSidebar />
                </Box>
            </Drawer>

            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Toolbar />
                {children}
                <div >footer</div>
            </Box>
        </>
    )
}

StandardProposalLayout.propTypes = {
    children: PropTypes.node
};