import DateFnsUtils from '@date-io/date-fns';
import Button from '@mui/material/Button';
import {  Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, InputLabel, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import useState from 'react-usestateref'
import { authProvider } from '../../authProvider';
import useRSocket from '../../lib/useRSocket';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useNavigate } from 'react-router-dom';

const defaultValues = {
    name: "",
    type: "",
    address: {
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        town: "",
        county: "",
        countryCode: "",
        countryName: "",
        postcode: "",
    }
}

const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    type: yup.string().required("Type is required"),
});

const contactManagementUrl = "ws://localhost:6090";

export const OrganisationForm = ({ organisationId = undefined }) => {

    const [socket, client, connected, isError, sendRequestResponse, sendRequestStream] = useRSocket(contactManagementUrl);
    const history = useNavigate();

    const { register, handleSubmit, control, reset, setValue, watch, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaultValues
    });

    const onSubmit = data => {
        console.log(data);
        createOrganisation(data);
    }

    function createOrganisation(payload) {
        console.log(payload)
        authProvider.getIdToken().then(token => {
            sendRequestResponse(socket, token.idToken.rawIdToken, "create.organisation", payload, (data) => navigateToViewOrganisation(data), (error) => console.log("createORganisation onError: " + String(error)), (sub) => console.log("createORganisation onSubscribe"))
        })
    }

    const navigateToViewOrganisation = (organisation) => {
        history("/contactmanagement/organisations/" + organisation.organisationId)
    }

    return (
        <>
            <Grid container >
                <Grid item xs={12} lg={6} padding={2}>
                    <Paper>
                        <form onSubmit={handleSubmit(onSubmit)} autoComplete="disabled">
                            <Grid container>
                                <Grid item xs={12} padding={2}>
                                    <Typography variant="h4" >New organisation</Typography>
                                </Grid>
                                <Grid item xs={12} md={6} padding={2}>
                                    <FormControl fullWidth={true}
                                        error={errors.name ? true : false}
                                    >
                                        <FormLabel>Name</FormLabel>
                                        <Controller
                                            render={({ field }) => <TextField {...field} autoComplete="new-password" />}
                                            name="name"
                                            control={control}
                                        />
                                        <FormHelperText>{errors.name?.message}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6} padding={2}>
                                    <FormControl fullWidth={true}
                                        variant="outlined"
                                        error={errors.type ? true : false}
                                    >
                                        <FormLabel>Type</FormLabel>
                                        <Controller
                                            render={({ field }) => (
                                                <Select {...field} >
                                                    <MenuItem value="">
                                                        <em>Please select...</em>
                                                    </MenuItem>
                                                    <MenuItem value="PLACING_BROKER">Placing broker</MenuItem>
                                                    <MenuItem value="PRODUCING_BROKER">Producing broker</MenuItem>
                                                    <MenuItem value="INSURER">Insurer</MenuItem>
                                                </Select>
                                            )}
                                            name="type"
                                            control={control}
                                        />
                                        <FormHelperText>{errors.type?.message}</FormHelperText>
                                    </FormControl>
                                </Grid>

                                <Grid container xs={12} padding={0}>

                                    <Grid item xs={12} paddingBottom={1} paddingX={2}>
                                        <FormControl fullWidth={true}
                                            error={errors.address?.addressLine1 ? true : false}
                                        >
                                            <FormLabel>Address</FormLabel>
                                            <Controller
                                                render={({ field }) => <TextField {...field} placeholder="Address line 1" autoComplete="new-password" />}
                                                name="address.addressLine1"
                                                control={control}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} paddingBottom={1} paddingX={2}>
                                        <FormControl fullWidth={true} >
                                            <Controller
                                                render={({ field }) => <TextField {...field} placeholder="Address line 2" autoComplete="new-password" />}
                                                name="address.addressLine2"
                                                control={control}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={6} paddingBottom={1} paddingX={2}>
                                        <FormControl fullWidth={true} >
                                            <Controller
                                                render={({ field }) => <TextField {...field} placeholder="City/Town" autoComplete="new-password" />}
                                                name="address.town"
                                                control={control}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={6} paddingBottom={1} paddingX={2}>
                                        <FormControl fullWidth={true} >
                                            <Controller
                                                render={({ field }) => <TextField {...field} placeholder="County" autoComplete="new-password" />}
                                                name="address.county"
                                                control={control}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={6} paddingBottom={1} paddingX={2}>
                                        <FormControl fullWidth={true} >
                                            <Controller
                                                render={({ field }) => <TextField {...field} placeholder="Postcode" autoComplete="new-password" />}
                                                name="address.postcode"
                                                control={control}
                                            />
                                            <FormHelperText>{errors.address?.addressLine1?.message}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid item xs={6} padding={2}>
                                    <Button color='secondary' onClick={reset}>Cancel</Button>
                                </Grid>
                                <Grid item xs={6} padding={2} >
                                    <Grid container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center" >
                                        <Button variant="contained" color='primary' type="submit">Save</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        </>
    )

}