import React, { Suspense } from 'react';
import { Route, useMatch, Routes } from 'react-router-dom';
import { OrganiationDashboard } from './OrganisationDashboard';
import { OrganisationForm } from './OrganisationForm';
import { OrganisationView } from './OrganisationView';
import { TeamForm } from './TeamForm';

const ContactRoutes = () => {

    const { path } = useMatch();

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Routes>
                {/* <Redirect
                    exact
                    from="/contactmanagement"
                    to="/contactmanagement/organisations/dashboard"
                />
                <Redirect
                    exact
                    from="/contactmanagement/organisations"
                    to="/contactmanagement/organisations/dashboard"
                /> */}
                <Route path="/contactmanagement/organisations/dashboard" exact>
                    <OrganiationDashboard />
                </Route>
                <Route path="/contactmanagement/organisations/new" exact={true}>
                    <OrganisationForm />
                </Route>
                <Route path="/contactmanagement/organisations/:organisationId/edit" exact={true}>
                    <OrganisationForm />
                </Route>
                <Route path="/contactmanagement/organisations/:organisationId" exact={true}>
                    <OrganisationView />
                </Route>
                <Route path="/contactmanagement/organisations/:organisationId/teams/new" exact={true}>
                    <TeamForm key="teamDetails" pageKey="teamDetails" />
                </Route>

                {/* <Redirect to="/not-found" /> */}
            </Routes>
        </Suspense>
    );
};

export default ContactRoutes;