import { AppBar, Grid, Paper, Tab, Tabs, Typography } from '@mui/material';
import { get, pick} from 'lodash';
// import { pick } from 'ramda';
import React, { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, Route, useParams, useMatch, Routes, Outlet } from 'react-router-dom';
import { authProvider } from '../../../authProvider';
import useRSocket from '../../../lib/useRSocket';
import { useProductState } from "../../Product/ProductContext";
import { useProposalDispatch, useProposalState } from "../Context/ProposalContext";
import { EditProposalCoverRiskDetails } from './EditProposalCoverRiskDetails';
import { LobCoverSelection } from './LobCoverSelectionTable';

const getInitialValues = (dataObject, fields) => {
   
    var initialValues = pick(dataObject, fields)
    return initialValues;
}

const TabPanel = ({ lob, tabDetails, cover }) => {

    if (tabDetails === "cover_selection") {
        return <LobCoverSelection lobName={lob} />
    }

    if (tabDetails === "cover_details") {
        return <EditProposalCoverRiskDetails key={cover} lobName={lob} coverName={cover} />
    }

    return (
        <EditProposalCoverRiskDetails />
    )
}

function ErrorFallback({ error, resetErrorBoundary }) {
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
            <button onClick={resetErrorBoundary}>Try again</button>
        </div>
    )
}

export const EditProposalLineOfBusinessPanel = ({ productVersion }) => {
    const { pageName } = useParams();

    return (
        <EditProposalLineOfBusinessPanelForm key={pageName} productVersion={productVersion} />
    )
}

export const EditProposalLineOfBusinessPanelForm = ({ productVersion }) => {

    const proposalUrl = "ws://localhost:6110";

    const { pageName, lobName } = useParams() ;
    const proposalState = useProposalState();
    const productState = useProductState();
    const proposalDispatch = useProposalDispatch();
    const pageFields = productState?.product?.productVersions[productVersion].lobs[lobName].pageDefinitions[pageName].fields
    const [socket, client, connected, isError, sendRequestResponse, sendRequestStream] = useRSocket(proposalUrl ) // process.env.REACT_APP_GPPAS_POLICY_URL);

    const formInitialValues = getInitialValues(proposalState.proposal.lobs[lobName], pageFields);

    const reactHookFormMethods = useForm({
        defaultValues: formInitialValues
    });

    const onSubmit = data => {
        proposalDispatch({ type: "SAVING_PROPOSAL" })
        const formData = { ...data, proposalId: proposalState.proposal.proposalId, key: lobName }
        console.log(formData);

        authProvider.getIdToken().then(token => {
            sendRequestResponse(socket, token.idToken.rawIdToken, "update.proposal.lob", formData, (data) => { handleResponse(data) }, (error) => console.log("update.proposal onError: " + String(error)), (sub) => console.log("update.proposal onSubscribe"))
            console.log("finished2")
        }).then(() => { console.log("finished") })
    }

    const handleNext = (e) => {
        reactHookFormMethods.handleSubmit(onSubmit)()
        console.log("set next tab")

    }

    const handleSave = (e) => {
        reactHookFormMethods.handleSubmit(onSubmit)();
    }

    const handleResponse = (data) => {
        proposalDispatch({ type: "EXPECTING_REFRESH", data: data })
        console.log("dispatch finished ")
        // setTab(nextTab.current);
    }

    return (
        <>
            <FormProvider {...reactHookFormMethods} >
                <form onSubmit={reactHookFormMethods.handleSubmit(onSubmit)}>
                    <LobCoverSelection lobName={lobName} pageDefinition={pageName} productVersion={productVersion}></LobCoverSelection>
                </form>
            </FormProvider>
        </>
    )

}

export const EditLineOfBusinessRoutes = ({ lobName, productVersion }) => {
    const { proposalId, policyPageName, lobPageName } = useParams();
    const { path } = useMatch();

    return (
        <Routes>
            {/* <Redirect
                exact strict
                from={`${path}`}
                to={`${path}/`}
            /> */}
            <Route exact path={`${path}/lob/:lobPageName`}>
                <EditProposalLineOfBusiness lobName={lobName} lobPageName={lobPageName} productVersion={productVersion}/>
            </Route>
            
        </Routes>
    )
}

export const EditProposalLineOfBusiness = ({ productVersion }) => {

    const {pageName, lobName} = useParams();
     
    console.log("EditProposalLineOfBusiness lob", lobName)

    const productState = useProductState();
    const proposalState = useProposalState();

    const [currentTab, setCurrentTab] = useState("lobDetails")

    const lob = productState?.product?.productVersions[productVersion]?.lobs[lobName];
    const covers = lob?.covers ? lob?.covers : {}
    const lobPages = lob?.pages ? lob?.pages : {};


    console.log("lob", lob)
    console.log("covers", covers)


    // var tabPanelDetails = { "cover_selection": { "panel": "cover_selection", "lob": lob } }

    const handleChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const reactHookFormMethods = useForm({
        defaultValues: {}
    });

    const onSubmit = async (data) => {
    }

    return (
        <>
            <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => {
                    // reset the state of your app so the error doesn't happen again
                }}
            >
                <FormProvider {...reactHookFormMethods} >
                    <form onSubmit={reactHookFormMethods.handleSubmit(onSubmit)}>
                        <Grid container >
                            <Grid item xs={12} paddingY={1} paddingX={0}>
                                <Paper>
                                    <TabLobBar lob={lobName} defaultTab="lobDetails" ></TabLobBar>
                                    <Outlet />

                                    {/* {
                                        Object.keys(lobPages).map((key, index) => {
                                            console.log("Lob Key", key)

                                            if (lobPageName === key) {
                                                return (
                                                    <EditProposalLineOfBusinessPanel lobName={lobName} lobPageName={key} pageDefinition={key} productVersion={productVersion} key={key}></EditProposalLineOfBusinessPanel>
                                                )
                                            }
                                            return ''
                                        })
                                    }
                                    {
                                        Object.keys(covers).map((key, index) => {
                                            if (lobPageName === key) {
                                                return (
                                                    <h1>{key}</h1>
                                                )
                                            }
                                            return <Typography variant='h1'>Invalid request 404</Typography>
                                        })
                                    } */}

                                    {/* <TabPanel value={currentTab} lob={lob} tabDetails={currentTab} key={currentTab} /> */}
                                </Paper>
                            </Grid>
                        </Grid>
                    </form>
                </FormProvider>
            </ErrorBoundary>
        </>
    )
}

const TabLobBar = ({ lob, defaultTab }) => {
    const productState = useProductState();
    const productVersion = 1;
    const [currentTab, setTab] = useState(defaultTab);

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const covers = productState?.product?.productVersions[productVersion]?.lobs[lob]?.covers ? productState?.product?.productVersions[productVersion]?.lobs[lob].covers : {}

    return (
        <>
            <AppBar position="static" color="secondary">
                <Tabs
                    value={currentTab}
                    onChange={handleChange}
                    textColor="inherit"
                    variant='scrollable'
                >
                    <Tab label="Cover selection" value="lobDetails" key="lobDetails" component={Link} to={"pages/lobDetails"} />
                    {
                        Object.keys(covers).map(key => {
                            // tabPanelDetails = { ...tabPanelDetails, [key]: { "panel": "cover_details", "lob": lob, "cover": key } }
                            return (
                                <Tab label={covers[key].name} value={key} key={key} component={Link} to={"covers/" + key } wrapped />
                            )
                        })
                    }
                </Tabs>
            </AppBar>
        </>
    )
}

