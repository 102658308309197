import useAxios from 'axios-hooks';
import React, { useEffect } from 'react'
import useThunkReducer from '../../../components/ThunkReducer/ThunkReducer';

const ProposalStateContext = React.createContext();
const ProposalDispatchContext = React.createContext()

const defaultInitialState = {
    isReady: false,
    isDirty: false,
    isLoading: false,
    isSaving: false,
    isExternalRefresh: false,
    expectedVersion: null,
    proposal: {}
}

const ProposalProvider = ({ reducer, proposalId, children }) => {
    const [state, dispatch] = useThunkReducer(reducer, { ...defaultInitialState })
    const [{ data, loading, error }] = useAxios( process.env.REACT_APP_GPPAS_GATEWAY_URL + '/api/v1/proposals/' + proposalId)

    // Given a proposal id, load the proposal initially    
    useEffect(() => {
        if (loading) {
            console.log("useEffect proposalId", proposalId)
            dispatch({ type: "LOADING_PROPOSAL" })
        }
        else {
            // Load proposal
            console.log( "proposal state data", data)
            dispatch({ type: "REFRESH_PROPOSAL", data: data })
        }
    }, [proposalId, dispatch, loading, data])

    return (
        <ProposalStateContext.Provider value={state}>
            <ProposalDispatchContext.Provider value={dispatch}>
                {children}
            </ProposalDispatchContext.Provider>
        </ProposalStateContext.Provider>
    )
}


const useProposalState = () => {
    const context = React.useContext(ProposalStateContext)
    if (context === undefined) {
        // throw new Error('useEntityState must be used within a EntityProvider')
        return undefined;
    }
    return context
}

function useProposalDispatch() {
    const context = React.useContext(ProposalDispatchContext)
    if (context === undefined) {
        throw new Error('useEntityDispatch must be used within a EntityProvider')
    }
    return context
}

function useProposalContext() {
    return [useProposalState, useProposalDispatch];
}

export { ProposalProvider, useProposalState, useProposalDispatch, useProposalContext }