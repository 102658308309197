import { FormControl, FormHelperText, FormLabel, TextField } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import parse from 'html-react-parser';
import NumberFormat from 'react-number-format';
import { RestartAltRounded } from '@mui/icons-material';

export const NumberFieldComponent = ({ item, questions, reactHookFormMethods, defaultAnswer }) => {

    const { control, setValue } = { ...reactHookFormMethods }
    const question = questions[item.question]
    const formName = ( item.level? item.level + "." : "" ) + ( item.formKey? item.formKey + "." : "" ) + question.key;

    return (
        <FormControl fullWidth={true}
        >
            <FormLabel>{parse(question.label)}</FormLabel>
            <Controller
                render={({ field: { onChange, ...rest } }) => {
                    return (
                        <NumberFormat
                            thousandSeparator={true}
                            {...question.numberOptions}
                            onValueChange={(values) => {
                                onChange(values.value)
                            }}
                            customInput={TextField}
                            isNumericString={true} {...rest} size="small" autoComplete="new-password" />
                        // <TextField {...field} size="small" autoComplete="new-password" />
                    )
                }}
                name={formName}
                control={control}
                defaultValue={defaultAnswer}
            />
            <FormHelperText>{question.helpText}</FormHelperText>
        </FormControl>
    )
}