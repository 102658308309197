import { TabContext } from '@mui/lab';
import { AppBar, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import { useLocation, useParams } from 'react-router';
import { ProductProvider } from '../../Product/ProductContext';
import { productReducer } from '../../Product/ProductReducer';
import { ProposalProvider, useProposalState } from '../Context/ProposalContext';
import { proposalReducer } from '../Context/ProposalReducer';
import { EditProposal } from './EditProposal';
import { EditProposalGeneralDetails, PageConfiguration } from './EditProposalGeneralDetails';
import { StandardProposalLayout } from './Layout/StandardProposalLayout';
import { TabProvider } from './Layout/TabContext';
import { ProposalSubscriptor } from './ProposalSubscriptor';

export const ProposalDashboardLayout = () => {

    const { proposalId } = useParams();


    return (
        <ProductProvider reducer={productReducer} productId="PSC">
            <ProposalProvider reducer={proposalReducer} proposalId={proposalId}>
                <ProposalSubscriptor >
                    <StandardProposalLayout>
                        <ProposalDashboard />
                    </StandardProposalLayout>
                </ProposalSubscriptor>
            </ProposalProvider>
        </ProductProvider>
    )
}

export const ProposalDashboard = () => {

    const proposal= useProposalState();

    return (
        <>
            <h1>Specific proposal dashboard</h1>
            <pre>{JSON.stringify(proposal, 2, 2)}</pre>
        </>
    )
}