import { Typography } from "@mui/material"
import { Suspense } from "react"
import AzureAD from "react-aad-msal"
import { authProvider } from "../../../authProvider"
import { useProposalState } from "../Context/ProposalContext"

export const ProposalSidebar = () => {

    const proposalState = useProposalState();

    if( proposalState.isLoading) {
        return (
            <Typography variant="h6">Loading</Typography>
        )
    }

    return (
        <>
            <Typography variant="h6">Proposal sidebar</Typography>
            <Typography variant="body1">{proposalState.proposal.proposalId}</Typography>
            <Typography variant="body1">{proposalState.proposal.version}</Typography>

            {JSON.stringify(proposalState, 2, 2)}

            <AzureAD provider={authProvider}>
                {
                    ({ login, logout, authenticationState, error, accountInfo }) => {
                        return (
                            <div>
                                <span>Only authenticated users can see me.</span>
                            </div>
                        )
                    }
                }
            </AzureAD>
        </>
    )
}