import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppointmentForm, { AppointmentView } from './Appointment';
import AppointmentDashboard from './AppointmentDashboard';

const AppointmentRoutes = () => {

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Routes>
                {/* <Redirect
                    exact
                    from="/appointments"
                    to="/appointments/dashboard"
                /> */}
                <Route path="/appointments/dashboard" exact>
                    <AppointmentDashboard />
                </Route>
                <Route path="/appointments/new" exact={true}>
                    <AppointmentForm />
                </Route>
                <Route path="/appointments/:appointmentId/edit" exact={true}>
                    <AppointmentForm />
                </Route>
                <Route path="/appointments/:appointmentId" exact={true}>
                    <AppointmentView />
                </Route>

                {/* <Redirect to="/not-found" /> */}
            </Routes>
        </Suspense>
    );
};

export default AppointmentRoutes;