import generalRiskDetails from './generalRiskDetails.json'
import financialInterestDetails from './financialInterestDetails.json'
import teamDetails from '../../components/ContactManagement/teamFormPage.json'
import questions from './questions.json'


// Help function that returns page config
// Will be moved to a server call later and probably put into
// a state component that caches the config

export function getPageConfiguration(pageKey) {

    switch (pageKey) {
        case "generalRiskDetails":
            return generalRiskDetails;
        case "financialInterestDetails":
            return financialInterestDetails;
        case "teamDetails":
            return teamDetails
        default:
            return {};
    }
}

export function getQuestionConfigurations() {
    return questions;
}