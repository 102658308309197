import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useProposalState, useProposalDispatch } from '../Context/ProposalContext';
import { useProductState } from '../../Product/ProductContext';
import { Button, Grid, Paper } from '@mui/material';
import { RenderPageDefinition } from '../RenderPageDefinition';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import useRSocket from '../../../lib/useRSocket';
import { authProvider } from '../../../authProvider';
import { useTabState } from './Layout/TabContext';
import useAxios from 'axios-hooks';
import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';
import mergeWith from 'lodash/mergeWith';
import { getActiveQuestionSets } from '../../../framework/common/common';

const defaultValues = {
}

const proposalUrl = "ws://localhost:6110";

function ignoreNullCustomiser(objectValue, sourceValue) {
    if (sourceValue === null || sourceValue === undefined) {
        return objectValue;
    }
}

const getInitialValues = (dataObject, fields) => {

    var initialValues = pick(dataObject, fields)

    return initialValues;
}

export const EditProposalTab = ({ pageDefinition, productVersion }) => {

    const productState = useProductState();
    const proposalState = useProposalState();
    const proposalDispatch = useProposalDispatch();
    const [currentTab, setTab ] = useState();
    const nextTab = useRef(currentTab);

    const pageFields = productState.product.productVersions[productVersion].pageDefinitions[pageDefinition].fields
    const pageItems = productState.product.productVersions[productVersion].pageDefinitions[pageDefinition].items

    const [socket, client, connected, isError, sendRequestResponse, sendRequestStream] = useRSocket(proposalUrl);

    const formInitialValues = getInitialValues(proposalState.proposal, pageFields);

    // console.log( "Default values:", formInitialValues)


    const reactHookFormMethods = useFormContext();
    const watchAllFields = reactHookFormMethods.watch();

    const product = productState?.product?.productVersions[productVersion];
    const questionSets = getActiveQuestionSets(product?.questionSets, reactHookFormMethods.watch)

    const [{ data: postData, loading: postLoading, error: postError }, executePost] = useAxios(
        {
            url: process.env.REACT_APP_GPPAS_GATEWAY_URL + "/api/v1/proposals/wait/" + proposalState.proposal.proposalId,
            method: 'POST'
        },
        { manual: true }
    )


    const onSubmit = async (data) => {
        proposalDispatch({ type: "SAVING_PROPOSAL" })
        const formData = { data: {...data}, proposalId: proposalState.proposal.proposalId }
        console.log(formData);

        // authProvider.getIdToken().then(token => {
        //     sendRequestResponse(socket, token.idToken.rawIdToken, "update.proposal", formData, (data) => { handleResponse(data) }, (error) => console.log("update.proposal onError: " + String(error)), (sub) => console.log("update.proposal onSubscribe"))
        //     console.log("finished2")
        // }).then(() => { console.log("finished") })

        await executePost({
            data: { ...formData }
        }).then((response) => {
            proposalDispatch({ type: "EXPECTING_REFRESH", data: response })
            console.log("Post done1 ", response)
        })

        console.log("Post done2 " + postData)
    }

    const handleNext = (e) => {
        reactHookFormMethods.handleSubmit(onSubmit)()
        console.log("set next tab")
        nextTab.current = "liability"

    }

    const handleSave = (e) => {
        reactHookFormMethods.handleSubmit(onSubmit)();
    }

    const handleResponse = (data) => {
        proposalDispatch({ type: "EXPECTING_REFRESH", data: data })
        console.log("dispatch finished " + nextTab.current)
        setTab(nextTab.current);
    }

    if (productState.isLoading) return <p>Loading...</p>

    return (
        <>

            <Grid container >
                <Grid item xs={12} paddingY={1} paddingX={0}>
                    <Paper>
                        <FormProvider {...reactHookFormMethods} >
                            <RenderPageDefinition questions={productState.product.productVersions[productVersion].questions} questionSets={questionSets} items={pageItems} reactHookFormMethods={reactHookFormMethods} />

                            <Grid item xs={12} >
                                <Grid container padding={1}
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center" >
                                    <Button sx={{ ml: 2 }} disabled={proposalState.isSaving} variant="contained" color='primary' type="button" onClick={(e) => handleSave(e)}>Save</Button>
                                    <Button sx={{ ml: 2 }} disabled={proposalState.isSaving} variant="contained" color='primary' type="button" onClick={(e) => handleNext(e)}>Next</Button>
                                </Grid>
                            </Grid>

                        </FormProvider>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container  >
                <Grid item xs={12}>
                    {JSON.stringify(watchAllFields, 2, 2)}
                </Grid>
            </Grid>

        </>
    )
}