import { FormControl, FormLabel, Grid, Paper, TextField, Toolbar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import useState from 'react-usestateref'
import { authProvider } from '../../authProvider';
import useRSocket from '../../lib/useRSocket';

const contactManagementUrl = "ws://localhost:6090";

export const OrganisationView = () => {

    const { organisationId } = useParams();

    const [organisation, setOrganisation] = useState({});
    const [loading, setLoading] = useState(true);
    const [socket, client, connected, isError, sendRequestResponse, sendRequestStream] = useRSocket(contactManagementUrl);

    const organisationLoaded = (data) => {
        console.log(data)
        setOrganisation(data);
        setLoading(false);
    }

    useEffect(() => {
        console.log(organisationId)
        if (connected && loading) {
            authProvider.getIdToken().then(token => {
                sendRequestResponse(socket, token.idToken.rawIdToken, "get.organisation", { organisationId: organisationId }, (data) => organisationLoaded(data), (error) => console.log("createAppointment onError: " + String(error)), (sub) => console.log("createAppointment onSubscribe"))
            })
        }
    }, [organisationId, connected])

    if (loading) {
        return (
            <p>Loading organisation</p>
        )
    } else {
        return (
            <>
                <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
                    <Toolbar />
                    <Grid container >
                        <Grid item xs={12} md={6} padding={1}>
                            <Paper >
                                <Grid container>
                                    <Grid item xs={12} padding={1}>
                                        <Typography variant="h4" >View organisation</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} padding={1}>
                                        <FormControl fullWidth={true}>
                                            <FormLabel>Name</FormLabel>
                                            <TextField disabled value={organisation.name} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6} padding={1} >
                                        <FormControl fullWidth={true}>
                                            <FormLabel>Type</FormLabel>
                                            <TextField disabled value={organisation.type} />
                                        </FormControl>
                                    </Grid>
                                    <Grid container xs={12}>

                                        <Grid item xs={12} padding={1} >
                                            <FormControl fullWidth={true} >
                                                <FormLabel>Address</FormLabel>
                                                <TextField disabled value={organisation.address?.addressLine1} />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} padding={1}>
                                            <FormControl fullWidth={true} >
                                                <TextField disabled value={organisation.address?.addressLine2} />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} md={6} padding={1}>
                                            <FormControl fullWidth={true} >
                                                <TextField disabled value={organisation.address?.town} />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} md={6} padding={1}>
                                            <FormControl fullWidth={true} >
                                                <TextField disabled value={organisation.address?.county} />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} md={6} padding={1}>
                                            <FormControl fullWidth={true} >
                                                <TextField disabled value={organisation.address?.postcode} />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6} padding={1} >

                            <Grid container
                                direction="column"
                                justify="center"
                                alignItems="stretch"

                            >
                                <Grid marginBottom={2}>
                                    <Paper>
                                        <Grid item xs={12} padding={1}>
                                            <Typography variant="h4" >Teams</Typography>
                                        </Grid>
                                        {
                                            Object.keys(organisation.teams).map(key => {
                                                return (
                                                    <>
                                                        <Grid xs={12} padding={1}>{organisation.teams[key].name}</Grid>
                                                    </>
                                                )
                                            })
                                        }
                                    </Paper>
                                </Grid>
                                <Grid>
                                    <Paper>
                                        <Grid item xs={12} padding={1}>
                                            <Typography variant="h4" >Members</Typography>
                                        </Grid>
                                        {
                                            Object.keys(organisation.memberDetails).map(key => {
                                                return (
                                                    <>
                                                        <Grid xs={12} padding={1}>{organisation.memberDetails[key].name}</Grid>
                                                    </>
                                                )
                                            })
                                        }
                                    </Paper>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Box>
            </>
        )
    }

}