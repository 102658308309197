export function productReducer(state, action) {

    console.log("productReducer " + action.type, [state, action])

    switch (action.type) {
        case 'SET_PRODUCT':
            return { product: action.data, isLoading: false };
        case 'LOADING_PRODUCT':
            return { product: {}, isLoading: true };
        default:
            return state;
    }
}