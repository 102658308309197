import React, { useEffect } from 'react';
import useState from 'react-usestateref'
import { authProvider } from '../../authProvider';
import useRSocket from '../../lib/useRSocket';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { Link as RouterLink, NavLink, useNavigate } from 'react-router-dom';
import { Grid, Toolbar } from '@mui/material';
import { Box } from '@mui/system';

const contactManagementUrl = "ws://localhost:6090";

export const OrganiationDashboard = ({ subscribe = true }) => { 

    const [organisations, setOrganisations, organisationsRef] = useState([]);
    const [socket, client, connected, isError, sendRequestResponse, sendRequestStream] = useRSocket(contactManagementUrl);
    const history = useNavigate();

    useEffect(() => {
        if (connected) {
            authProvider.getIdToken().then(token => {
                sendRequestResponse(socket, token.idToken.rawIdToken, "get.organisations", {}, (data) => setOrganisations(data), (error) => console.log("dashbaord onError: " + String(error)), (sub) => console.log("dashbaord onSubscribe"))
                // if (subscribe) {
                //     sendRequestStream(token.idToken.rawIdToken, "get.organisations.subscribe", {}, (data) => console.log(data), (data) => updateAppointment(data), (error) => console.log("dashbaord onError: " + String(error)), (sub) => console.log("dashbaord onSubscribe"))
                // }
            })
        }
    }, [connected])

    // const updateAppointment = (updatedAppointment) => {
    //     const index = appointmentsRef.current.findIndex(app => app.appointmentId === updatedAppointment.appointmentId)

    //     console.log(index)
    //     console.log(updatedAppointment)

    //     if (index === -1) {
    //         const updatedAppointments = [...appointmentsRef.current];
    //         updatedAppointments.push(updatedAppointment)
    //         console.log(updatedAppointments)
    //         setAppointments(updatedAppointments)
    //     }
    //     else {
    //         const updatedAppointments = [...appointmentsRef.current];
    //         updatedAppointments[index] = updatedAppointment;
    //         console.log(updatedAppointments)
    //         setAppointments(updatedAppointments);
    //     }
    //     // if (appointmentRef.current.appointmentId === updatedAppointment.appointmentId) {
    //     //   setAppointment(updatedAppointment);
    //     // }
    // }


    return (
        <>
            <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
                <Toolbar />
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item>
                        <h1>Organisations</h1>
                    </Grid>
                    <Grid item>
                        <Button color="primary" variant="contained" onClick={() => history("/contactmanagement/organisations/new")} >New organisation</Button>
                    </Grid>
                </Grid>
                <Grid container direction="column" >
                    <Grid item>
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Teams</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        organisations?.map((organisation, index) => (
                                            <TableRow key={organisation.organisationId}>
                                                <TableCell>
                                                    <RouterLink to={"/contactmanagement/organisations/" + organisation.organisationId}>
                                                        {organisation.name}
                                                    </RouterLink>
                                                </TableCell>
                                                <TableCell>{organisation.type}</TableCell>
                                                <TableCell>
                                                    {
                                                        Object.keys(organisation.teams).map(key => organisation.teams[key].name).join(', ')
                                                    }
                                                    <RouterLink to={"/contactmanagement/organisations/" + organisation.organisationId + "/teams/new"}>
                                                        <Button variant="contained" color="primary">New team</Button>
                                                    </RouterLink>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Box>
        </>
    )

}

export default OrganiationDashboard