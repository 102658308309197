import { Button, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { getActiveQuestionSets } from '../../../framework/common/common';
import { useProductState } from '../../Product/ProductContext';
import { useProposalState } from '../Context/ProposalContext';
import { RenderPageDefinition } from '../RenderPageDefinition';


export const EditProposalCoverRiskDetails = ({ productVersion }) => {
    const { coverName } = useParams();

    return (
        <EditProposalCoverRiskDetailsForm key={coverName} productVersion={productVersion} />
    )
}

export const EditProposalCoverRiskDetailsForm = ({ productVersion }) => {

    const { pageName, lobName, coverName } = useParams();

    const productState = useProductState();
    const proposalState = useProposalState();

    const cover = productState?.product?.productVersions['1']?.lobs[lobName]?.covers[coverName];
    const pageItems = cover?.pageDefinitions?.["coverDetails"]?.items ? cover.pageDefinitions["coverDetails"].items : []


    const reactHookFormMethods = useForm({
        defaultValues: {
            "lineOfBusinessKey": lobName,
            "coverKey": coverName
        }
    });

    const { register, handleSubmit, control, reset, setValue, watch, formState } = { ...reactHookFormMethods }
    const watchAllFields = watch();

    const questionSets = getActiveQuestionSets(cover?.questionSets, watch)

    const handleNext = (e) => {
        handleSubmit(onSubmit)()
    }

    const handleSave = (e) => {
        handleSubmit(onSubmit)();
    }

    const onSubmit = data => {
        console.log(data)
    }

    return (
        <>

            <Grid container >
                <Grid item xs={12} paddingY={1} paddingX={0}>
                    <Paper>
                        <FormProvider {...reactHookFormMethods} >
                            <form onSubmit={reactHookFormMethods.handleSubmit(onSubmit)}>

                                <RenderPageDefinition questions={productState.product.productVersions['1'].questions} questionSets={questionSets} items={pageItems} reactHookFormMethods={reactHookFormMethods} />

                                <Grid item xs={12} >
                                    <Grid container padding={1}
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center" >
                                        <Button sx={{ ml: 2 }} disabled={proposalState.isSaving} variant="outlined" color='secondary' type="button" onClick={(e) => handleSave(e)}>Save</Button>
                                        <Button sx={{ ml: 2 }} disabled={proposalState.isSaving} variant="contained" color='primary' type="button" onClick={(e) => handleNext(e)}>Next</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </FormProvider>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container  >
                <Grid item xs={12}>
                    <pre>{JSON.stringify(watchAllFields, 2, 2)}</pre>
                </Grid>
            </Grid>

        </>
    )
}