

export function getActiveQuestionSets(questionSets = {}, watch) {

    return Object.keys(questionSets).map((key => questionSets[key])).filter((item) => {
        if (item.askWhen) {
            const askWhenWatchedFieldValue = item.askWhen ? watch("questionAnswers." + item.askWhen?.questionKey) : ""
            const enableWhenValues = item.askWhen?.questionValues ? item.askWhen.questionValues : []
            if (enableWhenValues.includes(askWhenWatchedFieldValue)) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }).map( item => item.questions)
    .reduce( (current, item) => current.concat(item), [])
}